import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faFileInvoice, faBusinessTime } from '@fortawesome/free-solid-svg-icons';

const MainScreen = () => {
    const navigate = useNavigate();

    const tmsLogin = () => {
        navigate('/cn-login');
    };

    const redirectToMasterInvoice = () => {
        window.location.href = 'https://mastersinvoiceview.azurewebsites.net/';
    };
    const redirectToTimeSheet = () => {
        window.location.href = 'https://ts.selfaccounts.in/';
    };

    return (
        <>
            <div style={styles.container}>
                <div style={styles.innerContainer}>
                    <FontAwesomeIcon style={styles.cardicon} icon={faTruckFast} onClick={tmsLogin}/>
                    <button style={styles.button} onClick={tmsLogin}>Consignment Note</button>
                    
                </div>
                <div style={styles.innerContainer}>
                    <FontAwesomeIcon style={styles.cardicon} icon={faFileInvoice} onClick={redirectToMasterInvoice}/>
                    <button style={styles.button} onClick={redirectToMasterInvoice} >Master Invoice</button>
                </div>
                <div style={styles.innerContainer}>
                    <FontAwesomeIcon style={styles.cardicon} icon={faBusinessTime} onClick={redirectToTimeSheet}/>
                    <button style={styles.button} onClick={redirectToTimeSheet} >TimeSheet</button>
                </div>
            </div>
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        gap:'25px',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
    },
    innerContainer: {
        textAlign: 'center',
        backgroundColor: '#fff',
        padding: '50px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    header: {
        marginBottom: '30px',
        color: '#333',
    },
    button: {
        display: 'block',
        width: '200px',
        padding: '10px 20px',
        margin: '10px auto',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#198754',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    buttonHover: {
        backgroundColor: '#198754',
    },
    cardicon:{
        height:'50px'
    }
};

export default MainScreen;
