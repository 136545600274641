import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const FreightChart = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear()); // Set the default year to the current year
  const [availableYears, setAvailableYears] = useState([]); // Store available years

  const fetchFreightData = async (selectedYear) => {
    try {
      const response = await axios.get('https://tms-server1.azurewebsites.net/freightdata', {
        headers: {
          email: sessionStorage.getItem('email') // Replace with the actual client email
        },
        params: {
          year: selectedYear,
        },
      });

      const { availableYears, monthlyData } = response.data;

      if (Array.isArray(availableYears) && Array.isArray(monthlyData)) {
        setAvailableYears(availableYears);

        const months = monthlyData.map(item => {
          // Convert 'YYYY-MM' format to month name
          const monthNumber = parseInt(item.month.split('-')[1], 10); // Extract month number
          const monthName = new Date(selectedYear, monthNumber - 1, 1).toLocaleString('en-us', { month: 'long' });
          return monthName;
        });

        const freights = monthlyData.map(item => item.total_freight);

        setChartData({
          labels: months,
          datasets: [
            {
              label: 'Total Freight',
              data: freights,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        });
      } else {
        throw new Error('Data format is not as expected');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFreightData(year);
  }, [year]);

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value);
    setYear(selectedYear);
    setLoading(true);
    fetchFreightData(selectedYear);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <label htmlFor="year-select">Select Year: </label>
      <select style={{ padding:'5px', borderRadius:'5px'}} id="year-select" value={year} onChange={handleYearChange}>
        {availableYears.map(yearOption => (
          <option key={yearOption} value={yearOption}>
            {yearOption}
          </option>
        ))}
      </select>
      </div>
      <div style={{ width: '600px', height: '400px' }}>
        <Bar
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                }
              },
              y: {
                beginAtZero: true,
              }
            },
            plugins: {
              legend: {
                display: true,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Total Freight by Month',
              },
              datalabels: {
                display: true,
                color: 'black',
                formatter: (value, context) => {
                  return value;
                },
              },
            }
          }}
        />
      </div>
    </div>
  );
};

export default FreightChart;
