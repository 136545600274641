import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/additionalReceiptPreview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faPrint,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import numberToWords from '@jstb/num-to-words-indian';

const AdditionalReceiptPreview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { additionalreceiptno } = location.state || {};
    const [companyDetails, setCompanyDetails] = useState({});
    const [data, setData] = useState(null);
    const [dynamicData, setDynamicData] = useState([]);

    useEffect(() => {
        const fetchClient = async () => {
            try {
                const email = sessionStorage.getItem('email');
                const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
                    headers: { 'email': email }
                });
                setCompanyDetails(response.data);
            } catch (error) {
                console.error('Error fetching company details:', error);
                toast.error('Error fetching company details.');
            }
        };

        fetchClient();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const email = sessionStorage.getItem('email');
                const response = await axios.get('https://tms-server1.azurewebsites.net/getadditionalcashreceiptpreview', {
                    params: { additionalreceiptno, clientemail: email }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching cash receipt details:', error);
                toast.error('Error fetching cash receipt details.');
            }
        };

        fetchData();
    }, [additionalreceiptno]);

    useEffect(() => {
        const fetchDynamicRowData = async () => {
            try {
                const email = sessionStorage.getItem('email');
                const response = await axios.get('https://tms-server1.azurewebsites.net/getdynamicdata', {
                    params: { additionalreceiptno, clientemail: email }
                });
                setDynamicData(response.data);
            } catch (error) {
                console.error('Error fetching dynamic data:', error);
                toast.error('Error fetching dynamic data.');
            }
        };

        fetchDynamicRowData();
    }, [additionalreceiptno]);

    if (!data) {
        return <div>Loading...</div>;
    }

    const biltydatemoment = moment(data[0]?.biltyDate);
    const biltydateformattedDate = biltydatemoment.format('DD-MMMM-YYYY');
    const additionalcashdatemoment = moment(data[0]?.additionalreceiptdate);
    const additionalcashdateformattedDate = additionalcashdatemoment.format('DD-MMMM-YYYY');
    const totalGross = dynamicData.reduce((sum, detail) => sum + parseFloat(detail.grossTotal || 0), 0);
    const grossTotalWords = numberToWords(totalGross) + ' rupees only';

    return (
        <div className="additional-container">
            <div className="head-additionalcashreceipt">
                <div>
                    <h2 className='additionalheadercompany'>
                        {companyDetails?.company_name}
                        <button  className='no-print savebuttonpreview'  onClick={() => { navigate('/clientDashboard/showAdditionalCashReceipt') }}><FontAwesomeIcon icon={faArrowLeft} size="lg" /> Back</button>
                        <button className='no-print previewbtn' onClick={() => window.print()}>
                            <FontAwesomeIcon icon={faPrint} size="lg" /> Print
                        </button>
                    </h2>
                    <p className='additionalupperdata'>
                        Head Office: {companyDetails?.ho_address}, {companyDetails?.ho_city}, {companyDetails?.ho_state}, {companyDetails?.ho_pin_code}
                    </p>
                    {companyDetails.bo_address && companyDetails.bo_city && companyDetails.bo_state && companyDetails.bo_pin_code && (
                        <p className='additionalupperdata'>
                            Branch Office: {companyDetails.bo_address}, {companyDetails.bo_city}, {companyDetails.bo_state}, {companyDetails.bo_pin_code}
                        </p>
                    )}
                    <p className='additionalupperdata additionalcontactinfo'>
                        Contact: {companyDetails?.contact_no}, Email: {companyDetails?.email}
                    </p>
                </div>
                <div className="top-additionalcashreceipt">
                    <p className='additionalupperdata'>GST: {companyDetails.gstin}</p>
                    <h3 className='additionalupperdata'>Bill</h3>
                </div>
            </div>
            <div className="row-additionalcashreceipt row1-additionalcashreceipt">
                <p className='additionalreceiptno'>
                    Bill Number: <span className='rsno'>{data[0]?.billNo || "-"}</span>
                </p>
                <p>Date: <span className='cashspan'>{additionalcashdateformattedDate}</span></p>
            </div>
            <div>
                <p>
                    Received with thanks from Mr./Mrs/M/S <span className='cashspan'>{data[0]?.freightPayableBy || "-"}</span>
                  ,  Rupees <span className='cashspan'>{grossTotalWords || "-"}</span>
                  ,  Being The Freight Charges For the movement of Goods for the following:
                </p>
                {dynamicData.length > 0 && (
                    <div className="dynamic-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>GR No</th>
                                    <th>GR Date</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Gross Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dynamicData.map((detail, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{detail.grNo}</td>
                                        <td>{moment(detail.biltyDate).format('DD-MMM-YYYY')}</td>
                                        <td>{detail.billFrom}</td>
                                        <td>{detail.billTo}</td>
                                        <td>{detail.grossTotal}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                
            </div>
            <div className="additionalPreviewlist">
                <table className='additional-preview-table'>
                    <thead>
                        <tr>
                            <th className='additional-preview-th'>Freight</th>
                            <th className='additional-preview-th'>Transportation</th>
                            <th className='additional-preview-th'>Packing</th>
                            <th className='additional-preview-th'>Unpacking</th>
                            <th className='additional-preview-th'>Loading</th>
                            <th className='additional-preview-th'>Unloading</th>
                            <th className='additional-preview-th'>Insurance</th>
                            <th className='additional-preview-th'>Bike Charges</th>
                            <th className='additional-preview-th'>Car Transportation</th>
                            <th className='additional-preview-th'>GSTIN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.frieght || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.transportation || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.packing || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.unpacking || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.loading || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.unloading || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.insurance || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.bikecharges || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.cartransportation || "-"}</span></td>
                            <td className='additional-preview-td'><FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.gstin || "-"}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>Total: <FontAwesomeIcon icon={faIndianRupeeSign} /><span className='cashspan'>{data[0]?.total || "-"}</span></p>
            <p className='authorisedsignature'>
      <span className="cashspan"> For {companyDetails?.company_name}</span>
      </p>
      <p className='authorisedsignature'>
      <span className="cashspan"> Authorised Signatory</span>
      </p>
        </div>
    );
};

export default AdditionalReceiptPreview;
