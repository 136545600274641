import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import '../styles/PackingListForm.css'; // Make sure this path is correct
const Swal = require('sweetalert2');

const PackingListForm = () => {
    const email = sessionStorage.getItem('email');
    const [selectedGR, setSelectedGR] = useState('');
    const [formVisible, setFormVisible] = useState(false);
    const [tableRows, setTableRows] = useState([{ itemNo: '', markaNo: '', articles: '', packedBy: '', location: '', value: '', remark: '' }]);
    const [listNo, setListNo] = useState('');
    const [docketNo, setDocketNo] = useState('');
    const [date, setDate] = useState('');
    const [vehicleNo, setVehicleNo] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [consignor, setConsignor] = useState('');
    const [consignee, setConsignee] = useState('');
    const [grNumbers, setGrNumbers] = useState([]);
    const [billtyDetails, setBilltyDetails] = useState({});

    useEffect(() => {
        const fetchGRNumbers = async () => {
            try {
                const email = sessionStorage.getItem('email');
                const response = await axios.get('https://tms-server1.azurewebsites.net/packinglistgrnumber', {
                    headers: { email }
                });
                setGrNumbers(response.data.grNumbers);
            } catch (error) {
                console.error('Error fetching GR numbers:', error);
            }
        };

        fetchGRNumbers();
    }, []);

    useEffect(() => {
        const fetchListNO = async () => {
            try {
                if (!email) {
                    throw new Error('Email is not available in session storage');
                }

                const response = await axios.get('https://tms-server1.azurewebsites.net/getlistno', {
                    headers: {
                        'email': email
                    }
                });
                const { packinglistno } = response.data;
                setListNo(packinglistno);
            } catch (error) {
                toast.error(error.message);
            }
        };

        fetchListNO();
    }, [email]);

    const handleGRNoChange = async (e) => {
        const grNo = e.target.value;
        setSelectedGR(grNo);
        setFormVisible(grNo !== '');

        try {
            const response = await axios.get(`https://tms-server1.azurewebsites.net/billtydetails`, {
                headers: { 
                    email, 
                    'grNo': grNo 
                }
            });
            setBilltyDetails(response.data);
        } catch (error) {
            console.error('Error fetching billty details:', error);
        }
    };

    const addRow = () => {
        setTableRows([...tableRows, { itemNo: '', markaNo: '', articles: '', packedBy: '', location: '', value: '', remark: '' }]);
    };

    const deleteRow = (index) => {
        if (tableRows.length > 1) {
            const updatedRows = tableRows.filter((_, i) => i !== index);
            setTableRows(updatedRows);
        }
    };
    const validateRows = () => {
        for (let row of tableRows) {
            if (!row.markaNo && !row.particulars && !row.value && !row.itemNo && !row.packedBy && !row.remark && !row.location) {
                return false;
            }
        }
        return true;
    };

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = tableRows.map((row, i) => 
            i === index ? { ...row, [name]: value } : row
        );
        setTableRows(updatedRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateRows()) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Please fill out all fields in the table.',
                showConfirmButton: false,
                timer: 3000,
              });
            return;
        }
        const payload = {
            ...billtyDetails,
            listNo,
          email,
          tableRows
        };
        console.log(payload);
        
        try {
            const response = await axios.post('https://tms-server1.azurewebsites.net/savepackinglist', payload);
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Packing List successfully!',
                showConfirmButton: false,
                timer: 3000,
              });
            setTimeout(() => {
                 window.location.reload();
                 }, 2000);
        } catch (error) {
            toast.error('Failed to save packing list: ' + error.message);
        }
    };

    return (
        <div className="packing-list-container">
            <h2 className="packing-list-heading">Packing List</h2>

            <div className="packing-list-gr-selector">
                <label htmlFor="grNumber">Select GR Number: </label>
                <select id="grNumber" value={selectedGR} onChange={handleGRNoChange}>
                    <option value="">Select GR</option>
                    {grNumbers.map(grNumber => (
                        <option key={grNumber} value={grNumber}>{grNumber}</option>
                    ))}
                </select>
            </div>

            {formVisible && (
                <>
                    <form className="packing-list-form" onSubmit={handleSubmit}>
                        <div className="packing-list-row">
                            <div className="packing-list-form-group">
                                <label htmlFor="listNo">List No: </label>
                                <input type="text" id="listNo" value={listNo} className="packing-list-input" disabled />
                            </div>
                            <div className="packing-list-form-group">
                                <label htmlFor="docketNo">Docket No: </label>
                                <input type="text" id="docketNo" value={billtyDetails.grNo} disabled className="packing-list-input" />
                            </div>
                            <div className="packing-list-form-group">
                                <label htmlFor="date">Date: </label>
                                <input type="date" id="date" value={billtyDetails.billtydate} disabled className="packing-list-input" />
                            </div>
                            <div className="packing-list-form-group">
                                <label htmlFor="vehicleNo">Vehicle No: </label>
                                <input type="text" id="vehicleNo" value={billtyDetails.vehicleno} disabled className="packing-list-input" />
                            </div>
                        </div>
                        <div className="packing-list-row">
                            <div className="packing-list-form-group">
                                <label htmlFor="from">From: </label>
                                <input type="text" id="from" value={billtyDetails.inputfrom} disabled className="packing-list-input" />
                            </div>
                            <div className="packing-list-form-group">
                                <label htmlFor="to">To: </label>
                                <input type="text" id="to" value={billtyDetails.inputto} disabled className="packing-list-input" />
                            </div>
                            <div className="packing-list-form-group">
                                <label htmlFor="consignor">Consignor: </label>
                                <input type="text" id="consignor" value={billtyDetails.ConsignorName} disabled className="packing-list-input" />
                            </div>
                            <div className="packing-list-form-group">
                                <label htmlFor="consignee">Consignee: </label>
                                <input type="text" id="consignee" value={billtyDetails.ConsigneeName} disabled className="packing-list-input" />
                            </div>
                        </div>

                        <table className="packing-list-table">
                            <thead>
                                <tr>
                                    <th>Item No.</th>
                                    <th>Marka No.</th>
                                    <th>Article</th>
                                    <th>Packed By</th>
                                    <th>Location</th>
                                    <th>Value</th>
                                    <th>Remark</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((row, index) => (
                                    <tr key={index}>
                                        <td><input type="text" name="itemNo" value={row.itemNo} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td><input type="text" name="markaNo" value={row.markaNo} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td><input type="text" name="articles" value={row.articles} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td><input type="text" name="packedBy" value={row.packedBy} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td><input type="text" name="location" value={row.location} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td><input type="text" name="value" value={row.value} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td><input type="text" name="remark" value={row.remark} className="packing-list-input" onChange={(e) => handleChange(index, e)} /></td>
                                        <td>
                                        <button type="button" onClick={addRow}><FontAwesomeIcon icon={faPlus} /></button>
                                            {tableRows.length > 1 && (
                                                <button type="button" onClick={() => deleteRow(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        
                        <button type="submit" className="packing-list-submit-btn">Submit</button>
                    </form>
                </>
            )}
        </div>
    );
};

export default PackingListForm;
