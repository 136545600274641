import React, { useState, useEffect } from 'react';
import '../styles/Overlay.css'; // Import CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const Swal = require('sweetalert2');

const OverlayTabs = ({ isVisible, onClose, grNo }) => {
  const [insuranceDisabled, setInsuranceDisabled] = useState(false);
  const [formData, setFormData] = useState({
    deliveryOffice: { address: '', state: '', telephone: '' },
    insurance: { company: '', policyNo: '', date: '', amount: '', risk: '', ownerRisk: 0 },
    agent: { name: '', address: '' },
    demurrage: { days: '', rate: '' },
    caution: 'This Consignment will not be detained, diverted, re-routed or re-booked without Consignee Bank\'s written permission. Will be delivered at the destination.',
    notice: 'The consignment covered by this Lorry Receipt shall be stored at the destination under the control of the Transport Operator and shall be delivered to or to the order of the Consignee Bank whose Name is Mentioned in the Lorry Receipt. It will under no circumstances be delivered to anyone without the written authority from the Consignee Bank or its order, endorsed on the consignee copy.',
  });
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    if (grNo && email) {
      console.log("Fetching data for GR No:", grNo, "and Email:", email);
      
      const fetchData = async () => {
        try {
          const response = await fetch(`https://tms-server1.azurewebsites.net/getAdditionalData?grNo=${grNo}&email=${email}`);
          if (response.ok) {
            const data = await response.json();
            console.log("Fetched data:", data);
            setFormData(prevState => {
              console.log("Previous formData:", prevState);
              return {
                ...prevState,
                deliveryOffice: {
                  address: data?.deliveryofficeaddress || '',
                  state: data?.deliveryofficestate || '',
                  telephone: data?.deliveryofficetelephone || ''
                },
                insurance: {
                  company: data?.company || '',
                  policyNo: data?.policyNo || '',
                  date: data?.insurancedate || '',
                  amount: data?.insuranceamount || '',
                  risk: data?.risk || '',
                  ownerRisk: data?.ownerRisk || 0
                },
                agent: {
                  name: data?.agentName || '',
                  address: data?.agentaddress || ''
                },
                demurrage: {
                  days: data?.demurrageDays || '',
                  rate: data?.demurrageRate || ''
                },
                caution: data.caution || prevState.caution,
                notice: data.notice || prevState.notice,
              };
            });
            setInsuranceDisabled(data?.ownerRisk === 1);
            console.log("New formData:", data.deliveryOffice?.address);
            
          } else {
            console.error('Failed to fetch data');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [grNo, email]);

  // Handle change for input fields
  const handleChange = (section, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value,
      }
    }));
  };

  // Handle Owner Risk checkbox
  const handleOwnerRiskChange = (e) => {
    const isChecked = e.target.checked;
    setInsuranceDisabled(isChecked);
    
    setFormData(prevState => ({
      ...prevState,
      insurance: {
        ...prevState.insurance,
        ownerRisk: isChecked ? 1 : 0,
        ...(isChecked && { company: '', policyNo: '', date: '', amount: '', risk: '' }) // Clear insurance fields if ownerRisk is checked
      }
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the payload to send to the backend
    const payload = {
      grNo: grNo,
      email: email,
      formData: formData,
    };

    try {
      const response = await fetch('https://tms-server1.azurewebsites.net/saveAdditionalData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Data Submitted successfully!',
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <div className={`overlay ${isVisible ? 'show' : ''}`} onClick={onClose}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <div className="button-container">
          <button className="button-additionalinfo" type="submit" onClick={handleSubmit}>
            Submit
          </button>
          <button type="button" className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <form className="my-form-additionalinfo" onSubmit={handleSubmit}>
          {/* Delivery Office Section */}
          <span className="additionalinfo-para-heading">Delivery Office</span>
          <div className="form-section-additionalinfo grid-layout-additionalinfo">
            <label className="label-additionalinfo">
              Address:
              <input className="input-additionalinfo" type="text" value={formData.deliveryOffice.address} onChange={(e) => handleChange('deliveryOffice', 'address', e.target.value)} />
            </label>
            <label className="label-additionalinfo">
              State:
              <input className="input-additionalinfo" type="text" value={formData.deliveryOffice.state} onChange={(e) => handleChange('deliveryOffice', 'state', e.target.value)} />
            </label>
            <label className="label-additionalinfo">
              Telephone:
              <input className="input-additionalinfo" type="text" value={formData.deliveryOffice.telephone} onChange={(e) => handleChange('deliveryOffice', 'telephone', e.target.value)} />
            </label>
          </div>

          {/* Insurance Section */}
          <span className="additionalinfo-para-heading">Insurance</span>
          <label>
            Owner Risk
            <input type="checkbox" checked={formData.insurance.ownerRisk === 1} onChange={handleOwnerRiskChange} />
          </label>
          <div className="form-section-additionalinfo grid-layout-additionalinfo">
            <label className="label-additionalinfo">
              Company:
              <input className="input-additionalinfo" type="text" value={formData.insurance.company} onChange={(e) => handleChange('insurance', 'company', e.target.value)} disabled={insuranceDisabled} />
            </label>
            <label className="label-additionalinfo">
              Policy No:
              <input className="input-additionalinfo" type="text" value={formData.insurance.policyNo} onChange={(e) => handleChange('insurance', 'policyNo', e.target.value)} disabled={insuranceDisabled} />
            </label>
            <label className="label-additionalinfo">
              Date:
              <input className="input-additionalinfo" type="date" value={formData.insurance.date} onChange={(e) => handleChange('insurance', 'date', e.target.value)} disabled={insuranceDisabled} />
            </label>
            <label className="label-additionalinfo">
              Amount:
              <input className="input-additionalinfo" type="number" value={formData.insurance.amount} onChange={(e) => handleChange('insurance', 'amount', e.target.value)} disabled={insuranceDisabled} />
            </label>
            <label className="label-additionalinfo">
              Risk:
              <input className="input-additionalinfo" type="text" value={formData.insurance.risk} onChange={(e) => handleChange('insurance', 'risk', e.target.value)} disabled={insuranceDisabled} />
            </label>
          </div>

          {/* Agent Section */}
          <span className="additionalinfo-para-heading">Agent</span>
          <div className="form-section-additionalinfo grid-layout-additionalinfo">
            <label className="label-additionalinfo">
              Name:
              <input className="input-additionalinfo" type="text" value={formData.agent.name} onChange={(e) => handleChange('agent', 'name', e.target.value)} />
            </label>
            <label className="label-additionalinfo">
              Address:
              <input className="input-additionalinfo" type="text" value={formData.agent.address} onChange={(e) => handleChange('agent', 'address', e.target.value)} />
            </label>
          </div>

          {/* Demurrage Charges Section */}
          <span className="additionalinfo-para-heading">Demurrage Charges</span>
          <div className="form-section-additionalinfo grid-layout-additionalinfo">
            <label className="label-additionalinfo">
              Days:
              <input className="input-additionalinfo" type="number" value={formData.demurrage.days} onChange={(e) => handleChange('demurrage', 'days', e.target.value)} />
            </label>
            <label className="label-additionalinfo">
              Rate:
              <input className="input-additionalinfo" type="number" value={formData.demurrage.rate} onChange={(e) => handleChange('demurrage', 'rate', e.target.value)} />
            </label>
          </div>

          {/* Caution Section */}
          <span className="additionalinfo-para-heading">Caution</span>
          <div className="form-section-additionalinfo">
            <textarea className="textarea-additionalinfo" rows="3" value={formData.caution} onChange={(e) => handleChange('caution', 'caution', e.target.value)} />
          </div>

          {/* Notice Section */}
          <span className="additionalinfo-para-heading">Notice</span>
          <div className="form-section-additionalinfo">
            <textarea className="textarea-additionalinfo" rows="3" value={formData.notice} onChange={(e) => handleChange('notice', 'notice', e.target.value)} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default OverlayTabs;
