import React, { useState, useEffect } from "react";
import axios from 'axios';
import "../styles/updateClient.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditClient = () => {
  const [additionalAddress, setAdditionalAddress] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    owner_name: '',
    company_name: '',
    gstin: '',
    cin: '',
    ho_address: '',
    ho_state: '',
    ho_city: '',
    ho_pin_code: '',
    bank_name: '',
    bank_account_no: '',
    ifsc: '',
    branch: '',
    contact_no: '',
    invoice_prefix: '',
    email: '',
    password: '',
    bo_address: '',
    bo_state: '',
    bo_city: '',
    bo_pin_code: '',
    additionalreceipt:'',
    cashreceiptno:'',
    packinglistno:'',
    grno:'',
    gstRate:'',
    biltyText:'',
     alternateEmail:'',
    alternateContact:''
  });
  const [errors, setErrors] = useState({ gstin: '' });
  const [cerrors, csetErrors] = useState({ cin: '' });
  const [ifscmsg, setmsg] = useState({ ifsc: '' });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pinCodes, setPinCodes] = useState([]);
  const [bocities, setBoCities] = useState([]);
  const [bopinCodes, setBoPinCodes] = useState([]);
  const [emailmsg, setEmailMsg] = useState({ email: '' });
  const fetchClientDetails = async () => {
    
    try {
      const response = await axios.get(`https://tms-server1.azurewebsites.net/fetchClient/${id}`);
      const clientData = response.data;
      console.log(clientData);
        const initialFormData = clientData;
        setFormData(initialFormData);
        setLoading(false);
    } catch (error) {
      console.error('Error fetching client details:', error);
      toast.error('Error fetching client details');
    }
  };
  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  const handleStateChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'ho_state' && value) {
      try {
        const response = await axios.get('https://tms-server1.azurewebsites.net/getCities', { params: { state: value } });
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
        toast.error('Error fetching cities');
      }
    }

    if (name === 'bo_state' && value) {
      try {
        const response = await axios.get('https://tms-server1.azurewebsites.net/getCities', { params: { state: value } });
        setBoCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
        toast.error('Error fetching cities');
      }
    }
  };

  const handleCityChange = async (e) => {
    const { value } = e.target;
    setFormData({ ...formData, ho_city: value });

    if (value) {
      try {
        const response = await axios.get('https://tms-server1.azurewebsites.net/getPinCodes', { params: { city: value } });
        setPinCodes(response.data);
      } catch (error) {
        console.error('Error fetching pin codes:', error);
        toast.error('Error fetching pin codes');
      }
    }
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'gstin') {
      if (value.trim() === '') {
        setErrors({ ...errors, gstin: '' });
      } else {
        const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (!gstinRegex.test(value)) {
          setErrors({ ...errors, gstin: 'Invalid GSTIN format' });
        } else {
          setErrors({ ...errors, gstin: '' });
        }
      }
    }
    if (name === 'cin') {
      if (value.trim() === '') {
        csetErrors({ ...cerrors, cin: '' });
      } else {
        const cinRegex = /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

        if (!cinRegex.test(value)) {
          csetErrors({ ...cerrors, cin: 'Invalid CIN format' });
        } else {
          csetErrors({ ...cerrors, cin: '' });
        }
      }
    }

    if (name === 'ifsc') {
      if (value.trim() === '') {
        setmsg({ ...cerrors, ifsc: '' });
      } else {
        const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!ifscRegex.test(value)) {
          setmsg({ ...ifscmsg, ifsc: 'Invalid IFSC format' });
        } else {
          setmsg({ ...ifscmsg, ifsc: '' });
        }
      }
    }
    if (name === 'email') {
      if (value.trim() === '') {
        setEmailMsg({ ...emailmsg, email: '' });
      } else {
        const emailRegex = /^[a-zA-Z0-9_%-]+@[a-zA-Z0-9.-]+\.(com|in)$/;

        if (!emailRegex.test(value)) {
          setEmailMsg({ ...emailmsg, email: 'Invalid Email format' });
        } else {
          setEmailMsg({ ...emailmsg, email: '' });
        }
      }
    }
    if (name === 'alternateEmail') {
      if (value.trim() === '') {
        setEmailMsg({ ...emailmsg, email: '' });
      } else {
        const emailRegex = /^[a-zA-Z0-9_%-]+@[a-zA-Z0-9.-]+\.(com|in)$/;


        if (!emailRegex.test(value)) {
          setEmailMsg({ ...emailmsg, alternateemail: 'Invalid Email format' });
        } else {
          setEmailMsg({ ...emailmsg, alternateemail: '' });
        }
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const additionalAddressHandler = () => {
    setAdditionalAddress(!additionalAddress);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toast.success('Updating client...');
      const response = await axios.put(`https://tms-server1.azurewebsites.net/updateClient/${id}`, formData);
  
      // Check response status before displaying success message
      if (response.status === 200) {
        toast.success(response.data.message);
        // navigate('/show-clients'); // Optionally navigate back to show clients page after update
      } else {
        toast.error('Error updating client');
      }
    } catch (error) {
      console.error('Error updating client:', error);
      toast.error('Error updating client');
    }
  };
  
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="addclientcontainer">
      <button  className='addclientbuttonback' onClick={() => navigate('/adminDashboard/showClients')}><FontAwesomeIcon icon={faArrowLeft} size="lg"/></button>
      <form className="addclientform" onSubmit={handleSubmit} >
        <span className="para-heading">Company Details</span>
        <div className="addclientrow1">
          <label htmlFor="owner_name">Owner Name<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="owner_name" name="owner_name" placeholder="Enter Owner Name" value={formData?.owner_name} onChange={handleChange} required />

          <label htmlFor="company_name">Company Name<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="company_name" name="company_name" placeholder="Enter Company Name" value={formData?.company_name} onChange={handleChange} required />

          <label htmlFor="gstin">GSTIN<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="gstin" name="gstin" placeholder="Enter GSTIN" value={formData?.gstin} onChange={handleChange} required />
          {errors.gstin && <p className="error red">{errors.gstin}</p>}

          <label htmlFor="cin">CIN (if Applicable)<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="cin" name="cin" placeholder="Enter CIN" value={formData?.cin} onChange={handleChange} />
          {cerrors.cin && <p className="error red">{cerrors.cin}</p>}
        </div>

        <span className="para-heading">Head Office Details</span>
        <div className="addclientrow2">
          <label htmlFor="ho_address">Address<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="ho_address" name="ho_address" placeholder="Enter Address" value={formData?.ho_address} onChange={handleChange} required />

          <label htmlFor="ho_state">State<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="ho_state" name="ho_state" placeholder="Enter State" value={formData?.ho_state} onChange={handleChange}  />

          <label htmlFor="ho_city">City<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="ho_city" name="ho_city" value={formData?.ho_city} placeholder="Enter City" onChange={handleChange}  />

          <label htmlFor="ho_pin_code">Pincode<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="ho_pin_code" name="ho_pin_code" placeholder="Enter Pincode" value={formData?.ho_pin_code} onChange={handleChange}  />
        </div>

        <span className="para-heading">Bank Details</span>
        <div className="addclientrow3">
          <label htmlFor="bank_name">Bank Name<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="bank_name" name="bank_name" placeholder="Enter Bank Name" value={formData?.bank_name} onChange={handleChange} required />

          <label htmlFor="bank_account_no">Bank Account No.<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="number" id="bank_account_no" name="bank_account_no" placeholder="Enter Bank Account No" value={formData?.bank_account_no} onChange={handleChange} required />

          <label htmlFor="ifsc">IFSC<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="ifsc" name="ifsc" placeholder="Enter IFSC" value={formData?.ifsc} onChange={handleChange} required />

          <label htmlFor="branch">Branch<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="text" id="branch" name="branch" placeholder="Enter Branch" value={formData?.branch} onChange={handleChange} required />
        </div>
        {ifscmsg.ifsc && <p className="error red">{ifscmsg.ifsc}</p>}

        <span className="para-heading">Series</span>
        <div className="addclientrow3">
          <label htmlFor="grno">GR No.<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="number" id="grno" name="grno" placeholder="GR No." value={formData?.grno} onChange={handleChange} required  disabled/>

          <label htmlFor="packinglistno">Packing List No.<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="number" id="packinglistno" name="packinglistno" placeholder="Packing List No. " value={formData?.packinglistno} onChange={handleChange} required disabled/>

          <label htmlFor="cashreceiptno">Cash Receipt No.<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="number" id="cashreceiptno" name="cashreceiptno" placeholder="Cash Reciept No." value={formData?.cashreceiptno} onChange={handleChange} required disabled/>

          <label htmlFor="additionalreceipt">Additional Cash Receipt No.<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="number" id="additionalreceipt" name="additionalreceipt" placeholder="Additional Cash Receipt No." value={formData?.additionalreceipt} onChange={handleChange} required disabled/>
        </div>

        <span className="para-heading">Contact</span>
        <div className="addclientrow4">
          <label htmlFor="contact_no">Contact No.<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="number" id="contact_no" name="contact_no" placeholder="Enter Contact No." value={formData?.contact_no} onChange={handleChange} required />

          <label htmlFor="invoice_prefix">Invoice Prefix</label>
          <input className="addClientInput-edclientpg" type="text" id="invoice_prefix" name="invoice_prefix" placeholder="Enter Invoice Prefix" value={formData?.invoice_prefix} onChange={handleChange} />

          <label htmlFor="email">Email Address<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="email" id="email" name="email" placeholder="Enter Email Address" value={formData?.email} onChange={handleChange} required/>

          <label htmlFor="password">Password<span className="red">*</span></label>
          <input className="addClientInput-edclientpg" type="password" id="password" name="password" placeholder="Enter Password" minLength="8" value={formData?.password} onChange={handleChange} required disabled/>
          {emailmsg.email && <p className="error red">{emailmsg.email}</p>}
        </div>

        <span className="para-heading">Other Details</span>
        <div className="addclientrow4">
        <label htmlFor="gstRate">GST Rate</label>
        <select className="addClientInput-edclientpg" name="gstRate" id="gstRate" value={formData?.gstRate} onChange={handleChange}>
                <option value="">Select GST Rate</option>
                <option value="5.00">Under FCM @ 5%</option>
                <option value="12.00">Under FCM @ 12%</option>
                <option value="rcm">Under RCM</option>
                <option value="exempted">Exempted</option>
              </select>
          <label htmlFor="biltyText">Bilty Print Text</label>
          <input className="addClientInput-edclientpg" type="text" id="biltyText" name="biltyText" placeholder="Bilty Print Text" value={formData?.biltyText} onChange={handleChange} />
       
          
        <label htmlFor="email">Alternate Email Address</label>
          <input className="addClientInput-edclientpg" type="email" id="alternateEmail" name="alternateEmail" placeholder="Enter Alternates Email Address" value={formData?.alternateEmail} onChange={handleChange} />
          <label htmlFor="contact_no">Alternate Contact No.</label>
          <input className="addClientInput-edclientpg" type="number" id="alternateContact" name="alternateContact" placeholder="Enter Alternate Contact No." value={formData?.alternateContact} onChange={handleChange} />
          </div>
        {additionalAddress && (
          <>
            <span className="para-heading">Branch Office Details</span>
            <div className="addclientrow5">
              <label htmlFor="bo_address">Address</label>
              <input className="addClientInput-edclientpg" type="text" id="bo_address" name="bo_address" placeholder="Enter Address" value={formData?.bo_address} onChange={handleChange} />

              <label htmlFor="bo_state">State</label>
              <input type="text" className="addClientInput-edclientpg" id="bo_state" name="bo_state" value={formData?.bo_state} onChange={handleChange} />

              <label htmlFor="bo_city">City</label>
              <input type="text" className="addClientInput-edclientpg" id="bo_city" name="bo_city" value={formData?.bo_city} onChange={handleChange} />
              <label htmlFor="bo_pin_code">Pincode</label>
              <input type="text" className="addClientInput-edclientpg" id="bo_pin_code" name="bo_pin_code" value={formData?.bo_pin_code} onChange={handleChange} />
            </div>
          </>
        )}

        <div className="row6">
          <button className="addclientbutton" type="submit">Update Record</button>
          
          <button className="addclientbutton" type="button" onClick={additionalAddressHandler}>Additional Branch</button>
        </div>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false} // Remove close button
      />
    </div>
  );
};

export default EditClient;
