import React, { useState, useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/editBilty.css'
import OverlayTabs from './overlay';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash ,faPlus, faTimes ,faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AddLedgerOverlay from './addLedgerOverlay';

const Swal = require('sweetalert2')

const EditBilty =()=>{
    const location = useLocation();
    const navigate = useNavigate(); 
    const { grNo } = location.state || {};
    const [isOverlayVisible, setOverlayVisible] = useState(false);

    

    const [billtyDate, setBilltyDate] = useState('');
    const [companyDetails, setCompanyDetails] = useState({});
    const [rows, setRows] = useState([{ sNo: '', packages: '', description: '', rpq: '', actual: '', charges: '', ftl: '', disableRPQ: false, disableFTL: false ,disableActual:false,disableCharges:false}]);
    const [partyDetails, setPartyDetails] = useState([]);
    const [personalPartyDetails, setPersonalPartyDetails] = useState([]);
    const [combinedPartyDetails, setCombinedPartyDetails] = useState([]);
    const [isConsignorOtherSelected, setIsConsignorOtherSelected] = useState(false);
    const [isConsigneeOtherSelected, setIsConsigneeOtherSelected] = useState(false);
    const [isConsignorLiable, setIsConsignorLiable] = useState(false);
    const [isConsigneeLiable, setIsConsigneeLiable] = useState(false);
    const [freight, setFreight] = useState(0);
  const [billityCharge, setBillityCharge] = useState(0);
  const [kanta, setKanta] = useState(0);
  const [labour, setLabour] = useState(0);
  const [amount,setAmount]=useState(0);
  const [gstRate, setGstRate] = useState('');
  const [gstAmount, setGstAmount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [netPayable, setNetPayable] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [inputfrom,setInputFrom]=useState('')
  const [inputto,setInputTo]=useState('')
  const [vehicle,setVehicle]=useState('')
  const [frombr,setFromBr]=useState('')
  const [tobr,setToBr]=useState('')
  const [Private,setPrivate]=useState('')
  const [cog,setCog]=useState('')
  const [methodofP,setMethodOfP]=useState('')
  const [invoicenumber,setInvoiceNumber]=useState('')
  const [invoicevalue,setInvoiceValue]=useState('')
  const [eway,setEway]=useState('')
  const [validdate,setValidDate] = useState('')
  const [drivername,setDriverName]=useState('')
  const [drivermob,setDriverMob] = useState('')
  const [isSaved, setIsSaved] = useState(false);
  const [addLedgerVisible, setAddLedgerVisible] = useState(false);
  const [newbiltydate, setnewBiltydate] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDraft,setIsDraft]=useState(false);
  const [isFinal,setIsFinal]=useState(false);
  const [isCancel,setIsCancel]=useState(false)
  const openOverlay = () => {
    setOverlayVisible(true);
};
const closeOverlay = () => {
    setOverlayVisible(false);
};
  
  const email = sessionStorage.getItem('email');
  
  const [data, setData] = useState({ 
    vehicleno: '',
    inputfrom:'' ,
    frombr:'',
    inputto:'',
    billtydate:'',
    tobr:'',
    ConsignorName:'',
    consignorAddress:'',
    consignoreState:'',
    consignorPincode:'',
    consignorContact:'',
    consignorGstin:'',
    ConsigneeName:'',
    consigneeAddress:'',
    consigneeState:'',
    consigneePincode:'',
    consigneecontact:'',
    consigneeGstin:'',
     paymentStatus:'',
     rpq:'',
     actual:'',
     charges:'', 
     ftl:'',
     freight:'',
     billityCharge:'',
     kanta: '', 
     labour: '',
     amount: '',
     gstAmount: '',
     gstRate: '',
  grossTotal:'',
  advance:'',
  netPayable:'',
  Private:'',
  classificationOfGoods:'',
  methodofP:'',
  invoicenumber: '',
  invoicevalue: '', 
  eway: '',
  validdate: '',
  GSTPayer: '',
    drivername: '',
    drivermob: '',
    clientEmail:email
});



  const openLedgerOverlay = () => {
    setAddLedgerVisible(true);
  }

  
  const [consignorData, setConsignorData] = useState({
    party: '',
    address: '',
    address1: '',
    address2: '',
    address3: '',
    state: '',
    pincode: '',
    contact: '',
    gstin: '',
    type: ''
});

const [consigneeData, setConsigneeData] = useState({
    party: '',
    address: '',
    address1: '',
    address2: '',
    address3: '',
    state: '',
    pincode: '',
    contact: '',
    gstin: '',
    type: ''
});

    const addRow = () => {
    setRows([...rows, { sNo: '', packages: '', description: '', rpq: '', actual: '', charges: '',ftl:'', disableRPQ: false, disableFTL: false ,disableActual:false,disableCharges:false }]);
    };
    
    useEffect(() => {
        const fetchData = async () => {
        try {
            const email = sessionStorage.getItem('email'); // or localStorage.getItem('userEmail')
            
            const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
            headers: {
                'email': email
            }
            });
   
            setCompanyDetails(response.data);
        } catch (error) {
            console.error('Error fetching company details:', error);
        }
        };
        
        fetchData();
    }, []);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;

    // Update disableFTL based on rpq, actual, or charges
    if ((name === 'rpq' || name === 'actual' || name === 'charges') && value) {
        updatedRows[index].disableFTL = true;
    } else if ((name === 'rpq' || name === 'actual' || name === 'charges') && !value) {
        const anyNonEmpty = updatedRows[index].rpq || updatedRows[index].actual || updatedRows[index].charges;
        updatedRows[index].disableFTL = anyNonEmpty;
    }

    // Update disableRPQ, disableActual, and disableCharges based on ftl
    if (name === 'ftl' && value) {
        updatedRows[index].disableRPQ = true;
        updatedRows[index].disableActual = true;
        updatedRows[index].disableCharges = true;
    } else if (name === 'ftl' && !value) {
        updatedRows[index].disableRPQ = false;
        updatedRows[index].disableActual = false;
        updatedRows[index].disableCharges = false;
    }

    setRows(updatedRows);
    if (index === 0) {
        calculateFreight(updatedRows[0]);
      }

};

const calculateFreight = (row) => {

    if (row.ftl) {
      setFreight(row.ftl);
    } else if (row.rpq && row.charges) {
      setFreight(row.rpq * row.charges);
    } else {
      setFreight(0);
    }
  };

  const calculateAmount = () => {
      
    // setAmount(parseFloat(freight) + parseFloat(billityCharge) + parseFloat(kanta) + parseFloat(labour));
      
      return parseFloat(freight) + parseFloat(billityCharge) + parseFloat(kanta) + parseFloat(labour);
  };
  useEffect(() => {
    calculateFreight(rows[0]); // Assuming rows is your state containing the merged data
  }, [rows]);
  const calculateGST = () => {
    let amount = calculateAmount();
    let gst = 0;

    if (gstRate === '5.00') {
      gst = (amount * 5) / 100;
    } else if (gstRate === '12.00') {
      gst = (amount * 12) / 100;
    } else if (gstRate === 'rcm') {
      gst = 0;
    }
    else if (gstRate === 'exempted') {
        gst = 0;
      }

    setGstAmount(gst);
    setGrossTotal(amount + gst);
  };

  const handleAdvanceChange = (e) => {
    setAdvance(parseFloat(e.target.value) || 0);
  };

  useEffect(() => {
    calculateGST();
  }, [freight, billityCharge, kanta, labour, gstRate]);

  useEffect(() => {
    setNetPayable(grossTotal - advance);
  }, [grossTotal, advance]);

  useEffect(() => {
    if (netPayable === 0) {
        setPaymentStatus('paid');
    } else {
        setPaymentStatus('topay');
    }
}, [netPayable]);


        const deleteRow = (index) => {
        const updatedRows = rows.filter((row, i) => i !== index);
                setRows(updatedRows);
            };
        //
const [selectedPayer, setSelectedPayer] = useState('');

// Update checkbox change handlers
useEffect(() => {
    if (netPayable === 0) {
        setPaymentStatus('paid');
    } else if (paymentStatus === 'paid') {
        setPaymentStatus('topay');
    }
}, [netPayable]);

// Update checkbox change handlers
const handleLiableConsignorChange = (event) => {
    const isChecked = event.target.checked;
    setIsConsignorLiable(isChecked);
    if (isChecked) {
        setIsConsigneeLiable(false); // Uncheck consignee if consignor is checked
    }
    updatePayer(gstRate, true);
};

const handleLiableConsigneeChange = (event) => {
    const isChecked = event.target.checked;
    setIsConsigneeLiable(isChecked);
    if (isChecked) {
        setIsConsignorLiable(false); // Uncheck consignor if consignee is checked
    }
    updatePayer(gstRate, false);
};


const handleGstRateChange = (e) => {
    const rate = e.target.value;
    setGstRate(rate);
    updatePayer(rate, isConsignorLiable);
  };

  const updatePayer = (rate, consignorLiable) => {
    if (rate === "5.00" || rate === "12.00") {
      setSelectedPayer("Transporter");
    } else if (rate === "rcm") {
      setSelectedPayer(consignorLiable ? "Consignor" : "Consignee");
    } else if (rate === "exempted") {
      setSelectedPayer("Exempted");
    } else {
      setSelectedPayer("");
    }
  };


const handlePaymentStatusChange = (event) => {
    const { value } = event.target;
    setPaymentStatus(value);
};
            
  
    const currentDate = moment().format('YYYY-MM-DD');
  
    const [inputDate, setInputDate] = useState(currentDate);

    const handleDateChange = (event) => {
        setInputDate(event.target.value);
    };
  
    const handleToChange =(event) =>{
        setInputTo(event.target.value)
            }
            useEffect(() => {
            }, [isDraft, isFinal, isCancel]);
            console.log(isFinal,"isFinal");
            let savedasdraft = isDraft;
                let makeitfinal = isFinal;
                let cancelled = isCancel;
 const Updatebiltyasdraft = async (e) => {
                e.preventDefault();
                setIsDraft(true)
                setIsFinal(false)
                setIsCancel(false)     
              const email = sessionStorage.getItem('email');
              const freightPayableBy = isConsignorLiable ? consignorData.party : isConsigneeLiable ? consigneeData.party : 'None';
              const payload = {
                grNo: grNo|| null,
                billtydate: inputDate|| null,
                vehicleno: data.vehicleno|| null,
                inputfrom: data.inputfrom|| null,
                frombr: data.frombr|| null,
                inputto: data.inputto|| null,
                tobr: data.tobr|| null,
                ConsignorName: consignorData.party|| null,
                consignorAddress:consignorData.address|| null,
                consignoreState:consignorData.state|| null,
                consignorPincode:consignorData.pincode|| null,
                consignorContact:consignorData.contact|| null,
                consignorGstin:consignorData.gstin|| null,
                ConsigneeName: consigneeData.party|| null,
                consigneeAddress:consigneeData.address|| null,
                consigneeState:consigneeData.state|| null,
                consigneePincode:consigneeData.pincode|| null,
                consigneecontact:consigneeData.contact|| null,
                consigneeGstin:consigneeData.gstin|| null,
                rpq: rows[0]?.rpq || null,
                actual: rows[0]?.actual || null,
                charges: rows[0]?.charges || null,
                ftl: rows[0]?.ftl || null,
                freight: freight|| null,
                billityCharge: billityCharge|| null,
                kanta: kanta|| null,
                labour: labour|| null,
                amount: amount|| null,
                gstRate: gstRate || null,
                gstAmount: gstAmount || null,
                grossTotal: grossTotal|| null,
                advance: advance|| null,
                netPayable: netPayable|| null,
                paymentStatus: paymentStatus|| null,
                Private: Private|| null,
                classificationOfGoods: cog|| null,
                methodofP: methodofP|| null,
                invoicenumber: invoicenumber|| null,
                invoicevalue: invoicevalue|| null,
                eway: eway|| null,
                validdate: validdate|| null,
                GSTPayer: selectedPayer|| null,
                drivername:drivername|| null,
                drivermob:drivermob|| null,
                freightPayableBy:freightPayableBy|| null,
                consignorLiable:isConsignorLiable|| null,
                consigneeLiable:isConsigneeLiable|| null,
                clientEmail:email|| null,
                savedasdraft:true,
                                makeitfinal:false,
                                cancelled:false
            };
              const packingData = {
                rows: rows || null,
                clientEmail: email,
                grNo: grNo,
              };
                
                try {
                    console.log(payload, "Bilty data to update");                        
                    const response = await axios.post(`https://tms-server1.azurewebsites.net/updateBilty/${grNo}`, payload, {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      });
                
                    // Check response status before displaying success message
                    if (response.status === 200) {
                      toast.success(response.data.message);
                      // navigate('/show-clients'); // Optionally navigate back to show clients page after update
                      setIsSaved(true);
                    } else {
                      toast.error('Error updating bilty');
                    }
                  } catch (error) {
                    console.error('Error updating bilty:', error);
                    toast.error('Error updating bilty');
                  }

                  try {
                    console.log(packingData, "Bilty Packing data to update");        
                    const response = await axios.post(`https://tms-server1.azurewebsites.net/updatepackinglist/${grNo}`, packingData, {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      });
                
                
                    if (response.status === 200) {
                    //   toast.success(response.data.message);
                      
                    } else {
                      toast.error('Error updating packing list');
                    }
                  } catch (error) {
                    console.error('Error updating packing list:', error);
                    toast.error('Error updating packing list');
                  }


            };
            const Updatebiltyasfinal = async (e) => {
                e.preventDefault();
                setIsDraft(false)
                setIsFinal(true)
                setIsCancel(false)     
              const email = sessionStorage.getItem('email');
              const freightPayableBy = isConsignorLiable ? consignorData.party : isConsigneeLiable ? consigneeData.party : 'None';
            if (!inputDate) {
                toast.error('Please fill in the date');
                return;
            } else if (!data.vehicleno) {
                toast.error('Please fill vehicle number ');
                return;
            } else if (!data.inputfrom) {
                toast.error('Please fill in the From location');
                return;
            } else if (!data.inputto) {
                toast.error('Please fill in the To location');
                return;
            } else if (!consignorData.party) {
                toast.error('Please fill in the consignor party details');
                return;
            } else if (!consignorData.address) {
                toast.error('Please fill in the consignor address');
                return;
            } else if (!consignorData.contact) {
                toast.error('Please fill in the consignor contact details');
                return;
            } else if (!consigneeData.party) {
                toast.error('Please fill in the consignee party details');
                return;
            } else if (!consigneeData.address) {
                toast.error('Please fill in the consignee address');
                return;
            } else if (!consigneeData.contact) {
                toast.error('Please fill in the consignee contact details');
                return;
            } else if (!paymentStatus) {
                toast.error('Please select the payment status');
                return;
            } else if (!gstRate) {
                toast.error('Please fill in the GST rate');
                return;
            } else if (!grossTotal) {
                toast.error('Please fill ftl or rate qtl and weights');
                return;
            }
            if (!isConsigneeLiable && !isConsignorLiable) {
                toast.error('Please select a freight payer');
                return;
            }
              const payload = {
                grNo: grNo|| null,
                billtydate: inputDate|| null,
                vehicleno: data.vehicleno|| null,
                inputfrom: data.inputfrom|| null,
                frombr: data.frombr|| null,
                inputto: data.inputto|| null,
                tobr: data.tobr|| null,
                ConsignorName: consignorData.party|| null,
                consignorAddress:consignorData.address|| null,
                consignoreState:consignorData.state|| null,
                consignorPincode:consignorData.pincode|| null,
                consignorContact:consignorData.contact|| null,
                consignorGstin:consignorData.gstin|| null,
                ConsigneeName: consigneeData.party|| null,
                consigneeAddress:consigneeData.address|| null,
                consigneeState:consigneeData.state|| null,
                consigneePincode:consigneeData.pincode|| null,
                consigneecontact:consigneeData.contact|| null,
                consigneeGstin:consigneeData.gstin|| null,
                rpq: rows[0]?.rpq || null,
                actual: rows[0]?.actual || null,
                charges: rows[0]?.charges || null,
                ftl: rows[0]?.ftl || null,
                freight: freight|| null,
                billityCharge: billityCharge|| null,
                kanta: kanta|| null,
                labour: labour|| null,
                amount: amount|| null,
                gstRate: gstRate || null,
                gstAmount: gstAmount || null,
                grossTotal: grossTotal|| null,
                advance: advance|| null,
                netPayable: netPayable|| null,
                paymentStatus: paymentStatus|| null,
                Private: Private|| null,
                classificationOfGoods: cog|| null,
                methodofP: methodofP|| null,
                invoicenumber: invoicenumber|| null,
                invoicevalue: invoicevalue|| null,
                eway: eway|| null,
                validdate: validdate|| null,    
                GSTPayer: selectedPayer|| null,
                drivername:drivername|| null,
                drivermob:drivermob|| null,
                freightPayableBy:freightPayableBy|| null,
                consignorLiable:isConsignorLiable|| null,
                consigneeLiable:isConsigneeLiable|| null,
                clientEmail:email|| null,
                savedasdraft:false,
                makeitfinal:true,
                cancelled:false
            };
           const packingData ={
            rows: rows || null,
            clientEmail:email,
            grNo: grNo,
           }
                
                try {
          
                    const response = await axios.post(`https://tms-server1.azurewebsites.net/updateBilty/${grNo}`, payload);
                
                    // Check response status before displaying success message
                    if (response.status === 200) {
                      toast.success(response.data.message);
                      // navigate('/show-clients'); // Optionally navigate back to show clients page after update
                      setIsSaved(true);
                    } else {
                      toast.error('Error updating bilty');
                    }
                  } catch (error) {
                    console.error('Error updating bilty:', error);
                    toast.error('Error updating bilty');
                  }

                  try {
                    const response = await axios.post(`https://tms-server1.azurewebsites.net/updatepackinglist/${grNo}`, packingData);
                
                
                    if (response.status === 200) {
                    //   toast.success(response.data.message);
                      
                    } else {
                      toast.error('Error updating packing list');
                    }
                  } catch (error) {
                    console.error('Error updating packing list:', error);
                    toast.error('Error updating packing list');
                  }


            };
          
        
            
            const fetchParties = async () => {
                try {
                    const email = sessionStorage.getItem('email');
                    const response = await axios.get('https://tms-server1.azurewebsites.net/parties', {
                        headers: {
                            'x-client-email': email
                        }
                    });
                    const businessParties = response.data.map(party => ({ ...party, type: 'Business ' }));
                    return businessParties;
                } catch (error) {
                    console.error('Error fetching parties:', error);
                    return [];
                }
            };
            
            const fetchPersonalParties = async () => {
                try {
                    const email = sessionStorage.getItem('email');
                    const response = await axios.get('https://tms-server1.azurewebsites.net/personalparties', {
                        headers: {
                            'x-client-email': email
                        }
                    });
                    const personalParties = response.data.map(party => ({ ...party, type: 'Personal ', contact: party.contact }));
                    return personalParties;
                } catch (error) {
                    console.error('Error fetching personal parties:', error);
                    return [];
                }
            };  
            
            const fetchAllParties = async () => {
                try {
                    const [businessParties, personalParties] = await Promise.all([fetchParties(), fetchPersonalParties()]);
        
                    let mergedParties = businessParties.map(party => ({ ...party, type: 'Business ' }));
        
                    personalParties.forEach(personalParty => {
                        const existingPartyIndex = mergedParties.findIndex(party => party.name === personalParty.name);
                        if (existingPartyIndex === -1) {
                            mergedParties.push({ ...personalParty, type: 'Personal ', contact: personalParty.contact });
                        } else {
                            mergedParties.push({ ...personalParty, type: 'Personal ', contact: personalParty.contact });
                        }
                    });
        
                    setCombinedPartyDetails(mergedParties);
                    return mergedParties;
                } catch (error) {
                    console.error('Error fetching all parties:', error);
                    setCombinedPartyDetails([]);
                    return [];
                }
            };
            
            useEffect(() => {
                fetchAllParties();
            }, []);
    const closeLedgerOverlay = () => {
        setAddLedgerVisible(false);
        fetchAllParties(); 
      };

      const handleConsignorChange = (e) => {
        const { name, value } = e.target;
        if (name === 'party') {
            setIsConsignorOtherSelected(value === 'Other');
            const selected = combinedPartyDetails.find(p => p.name === value);
            if (selected) {
                if (selected.type === 'Business ') {
                    setConsignorData({
                        ...consignorData,
                        ...selected,
                        type: 'Business ',
                        party: value
                    });
                } else if (selected.type === 'Personal ') {
                    setConsignorData({
                        ...consignorData,
                        party: value,
                        address: '',
                        address1: selected.address1,
                        address2: selected.address2,
                        address3: selected.address3,
                        state: '',
                        pincode: '',
                        gstin: '',
                        contact: selected.contact,
                        type: 'Personal '
                    });
                }
            }
        } else {
            setConsignorData(prevState => ({ ...prevState, [name]: value }));
        }
    };
    
    const handleConsigneeChange = (e) => {
        const { name, value } = e.target;
        if (name === 'party') {
            setIsConsigneeOtherSelected(value === 'Other');
            const selected = combinedPartyDetails.find(p => p.name === value);
            if (selected) {
                if (selected.type === 'Business ') {
                    setConsigneeData({
                        ...consigneeData,
                        ...selected,
                        type: 'Business ',
                        party: value
                    });
                } else if (selected.type === 'Personal ') {
                    setConsigneeData({
                        ...consigneeData,
                        party: value,
                        address: '',
                        address1: selected.address1,
                        address2: selected.address2,
                        address3: selected.address3,
                        state: '',
                        pincode: '',
                        gstin: '',
                        contact: selected.contact,
                        type: 'Personal '
                    });
                }
            }
        } else {
            setConsigneeData(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const renderConsignorAddressFields = () => {
        if (consignorData.type === 'Personal ') {
            return (
                <select
                    className="consignoraddress"
                    name="address"
                    value={consignorData.address}
                    onChange={handleConsignorChange}
                    
                >
                    <option value="">Select Address</option>
                    <option value={consignorData.address1}>{consignorData.address1}</option>
                    {consignorData.address2 && <option value={consignorData.address2}>{consignorData.address2}</option>}
                    {consignorData.address3 && <option value={consignorData.address3}>{consignorData.address3}</option>}
                </select>
            );
        }
        return (
            <input
                className="consignoraddress"
                type="text"
                id="address"
                name="address"
                placeholder="Enter Address"
                value={consignorData.address}
                onChange={handleConsignorChange}
              
            />
        );
    };
    
    const renderConsigneeAddressFields = () => {
        if (consigneeData.type === 'Personal ') {
            return (
                <select
                    className="consignoraddress"
                    name="address"
                    value={consigneeData.address}
                    onChange={handleConsigneeChange}
                 
                >
                    <option value="">Select Address</option>
                    <option value={consigneeData.address1}>{consigneeData.address1}</option>
                    {consigneeData.address2 && <option value={consigneeData.address2}>{consigneeData.address2}</option>}
                    {consigneeData.address3 && <option value={consigneeData.address3}>{consigneeData.address3}</option>}
                </select>
            );
        }
        return (
            <input
                className="consignoraddress"
                type="text"
                id="conaddress"
                name="address"
                placeholder="Enter Address"
                value={consigneeData.address}
                onChange={handleConsigneeChange}
             
            />
        );
    };


  useEffect(() => {
  
    const fetchBiltydate = async () => {
    
      const billtyDateMomentt = moment(billtyDate, 'YYYY-MM-DD');


      setnewBiltydate(billtyDateMomentt);
    };

    fetchBiltydate();
  }, []);
  
  const fetchPackingList = async () => {
    try {
        const packingResponse = await axios.get('https://tms-server1.azurewebsites.net/getpackinglist', {
            params: { grNo, clientemail: sessionStorage.getItem('email') }
        });
        const packingdata = packingResponse.data.packingList;

        const biltyResponse = await axios.get('https://tms-server1.azurewebsites.net/getbiltypreview', {
            params: { grNo, clientemail: sessionStorage.getItem('email') }
        });
        const biltydata = biltyResponse.data[0]; // Assuming biltydata is an object

        // Merge packingdata with biltydata into updatedRows
        const updatedRows = packingdata.map(item => ({
            sNo: item.sNo || '',
            packages: item.packages || '',
            description: item.description || '',
            rpq: biltydata.rpq || '',
            actual: biltydata.actual || '',
            charges: biltydata.charges || '',
            ftl: biltydata.ftl || '',
            disableRPQ: !!biltydata.ftl,
            disableActual: !!biltydata.ftl,
            disableCharges: !!biltydata.ftl,
            disableFTL: !!biltydata.rpq || !!biltydata.actual || !!biltydata.charges
        }));

        setRows(updatedRows);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
  useEffect(() => {
    fetchPackingList();
  }, [grNo]);
  const fetchData = async () => {
    try {
        setLoading(true);
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/getbiltypreview', {
            params: { grNo, clientemail: email }
        });
        const biltyData = response.data[0];
setData(biltyData)
        const mergedParties = await fetchAllParties();
        const initialConsignorParty = biltyData.ConsignorName;
        const initialConsigneeParty = biltyData.ConsigneeName;

        setConsignorData(prevState => ({
            ...prevState,
            party: initialConsignorParty
        }));

        setConsigneeData(prevState => ({
            ...prevState,
            party: initialConsigneeParty
        }));

        const selectedConsignorParty = mergedParties.find(p => p.name === initialConsignorParty);
        if (selectedConsignorParty) {
            if (selectedConsignorParty.type === 'Business ') {
                setConsignorData({
                    ...selectedConsignorParty,
                    type: 'Business ',
                    party: initialConsignorParty
                });
            } else if (selectedConsignorParty.type === 'Personal ') {
                setConsignorData({
                    party: initialConsignorParty,
                    address: '',
                    address1: selectedConsignorParty.address1,
                    address2: selectedConsignorParty.address2,
                    address3: selectedConsignorParty.address3,
                    state: '',
                    pincode: '',
                    gstin: '',
                    contact: selectedConsignorParty.contact,
                    type: 'Personal '
                });
            }
        }

        const selectedConsigneeParty = mergedParties.find(p => p.name === initialConsigneeParty);
        if (selectedConsigneeParty) {
            if (selectedConsigneeParty.type === 'Business ') {
                setConsigneeData({
                    ...selectedConsigneeParty,
                    type: 'Business ',
                    party: initialConsigneeParty
                });
            } else if (selectedConsigneeParty.type === 'Personal ') {
                setConsigneeData({
                    party: initialConsigneeParty,
                    address: '',
                    address1: selectedConsigneeParty.address1,
                    address2: selectedConsigneeParty.address2,
                    address3: selectedConsigneeParty.address3,
                    state: '',
                    pincode: '',
                    gstin: '',
                    contact: selectedConsigneeParty.contact,
                    type: 'Personal '
                });
            }
        }
      setFreight(biltyData.freight || 0);
      setBillityCharge(biltyData.billityCharge || 0);
      setKanta(biltyData.kanta || 0);
      setLabour(biltyData.labour || 0);
      setAmount(biltyData.amount || 0);
      setGstRate(biltyData.gstRate || 0)
      setAdvance(biltyData.advance || 0)
      updatePayer(biltyData.gstRate);
      setPrivate(biltyData.Private)
      setCog(biltyData.classificationOfGoods)
      setMethodOfP(biltyData.methodofP)
      setInvoiceNumber(biltyData.invoicenumber)
      setInvoiceValue(biltyData.invoicevalue)
      setEway(biltyData.eway)
      setValidDate(biltyData.validdate)
      setDriverName(biltyData.drivername)
      setDriverMob(biltyData.drivermob)
      setInputDate(biltyData.billtydate)
      setIsConsigneeLiable(biltyData.consigneeLiable)
      setIsConsignorLiable(biltyData.consignorLiable)

        setLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
};
  useEffect(() => {
    fetchData();
  }, [grNo]);

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  return(
        <>
        <form  className="biltyForm" onSubmit={Updatebiltyasdraft} >
            
        <div className='invoice-container'>
        <div className='invoice-row-0'>
            <button  className='addclientbuttonback' onClick={() => navigate('/clientDashboard/showBillty')}><FontAwesomeIcon icon={faArrowLeft} size="lg"/></button>
                <h2 className='invoice-heading'>{companyDetails.company_name}, {companyDetails.ho_address}, {companyDetails.ho_city}, {companyDetails.ho_state}, {companyDetails.ho_pin_code}</h2>
                <div className='editoverlayTabs'>
                <button type='button' onClick={openOverlay}>Additional Information</button>
                <OverlayTabs grNo={grNo} isVisible={isOverlayVisible} onClose={closeOverlay} />    
                </div> 
                {/* <div className='overlayTabs'>
                <button type='button' onClick={opencashOverlay}>Cash Receipt</button>

                <CashReceiptOverlay isVisible={isCashReceiptOverlayVisible} docketno={grNo} from ={inputfrom} to={inputto} grossTotal={grossTotal} billtyDate={inputDate} onClose={closecashOverlay} /> 
                <button type='button' onClick={openAdditionalReceiptOverlay}>Additional Receipt</button>
                <AdditionalReceiptOverlay isVisible={isAdditionalReceiptOverlayVisible}  grossTotal={grossTotal} videlr={grNo} billtyDate={inputDate} from ={inputfrom} to={inputto} consignorName={consignorData.party} onClose={closeAdditionalReceiptOverlay} />
                <button type='button' onClick={openPackingListOverlay}>Packing List</button>
                <PackingListOverlay isVisible={isPackingListOverlayVisible} from ={inputfrom} to={inputto} docketno={grNo} consigneeName={consigneeData.party} consignorName={consignorData.party} onClose={closePackingListOverlay} date={inputDate}/>    
                </div> */}
                </div>
                <div className='invoice-row-1'>
                <div>
                        <label className="invoice-label-row1" htmlFor="gr_number">GR Number<span className="red">*</span></label>
                        <input
                            className="invoice-input-row1"
                            type="number"
                            id="gr_number"
                            name="gr_number"
                            value={grNo}
                            placeholder="GR No."
                            disabled
                        />
                    </div>
                    <div>
                        <label className="invoice-label-row1" htmlFor="invoicedate">Date<span className="red">*</span></label>
                        <div className="date-selector">
                        <input
          className={`invoice-input-row1 ${inputDate === currentDate ? 'current-date' : 'previous-date'}`}
          type="date"
          id="invoicedate"
          name="invoicedate"
          value={inputDate}
          onChange={handleDateChange}
          min={newbiltydate}
          max={currentDate}
          disabled
        />
      </div>
</div>
            <div>
            <label className="invoice-label-row1" htmlFor="vehicle_number">Vehicle Number<span className="red">*</span></label>
            <input className="invoice-input-row1" type="text" id="vehicle_number" name="vehicleno" placeholder="Vehicle No."  value={data.vehicleno}  onChange={handleChange} /></div>
            <div>
            <label className="invoice-label-row1" htmlFor="from">From<span className="red">*</span></label>
            <input className="invoice-input-row1" type="text" id="from" name="inputfrom" placeholder="City" onChange={handleChange} value={data.inputfrom}  /></div>
            <div>
            <label className="invoice-label-row1" htmlFor="fbrcode">Br. code</label>
            <input className="invoice-input-row1" type="number" id="fbrcode" name="frombr" placeholder="BR Code" value={data.frombr} onChange={handleChange}  />
            </div>
            <div>
            <label className="invoice-label-row1" htmlFor="to">To<span className="red">*</span></label>
            <input className="invoice-input-row1" type="text" id="to" name="inputto" placeholder="City" onChange={handleChange} value={data.inputto} /></div>
            <div>
            <label className="invoice-label-row1" htmlFor="tbrcode">Br. code</label>
            <input className="invoice-input-row1" type="number" id="tbrcode" name="tobr" placeholder="BR Code" value={data.tobr} onChange={handleChange}   />
            </div>
            </div>
            <div className="invoice-row-2">
            <div className="row-2-left">
            <div className='consignor'>
                <div className='liable'>
                    <h4 className='consignorheader'>Consignor</h4>
                    <div>
                        <button type='button' onClick={() => setAddLedgerVisible(true)}>Add Consignor</button>
                        <AddLedgerOverlay isVisible={addLedgerVisible} onClose={closeLedgerOverlay} />
                    </div>
                    <h4 className='consignorheader'>
                        Liable to pay
                        <input
                            type="checkbox"
                            id="consignor"
                            name="consignor"
                            value="consignor"
                            checked={isConsignorLiable}
                            onChange={handleLiableConsignorChange}
                        />
                    </h4>
                </div>
                <select className='partyname' name="party" onChange={handleConsignorChange} value={consignorData.party}>
                    <option value="">Select Consignor</option>
                    {combinedPartyDetails.map((party) => (
                        <option key={party.id} value={party.name}>{`${party.name} - ${party.type}`}</option>
                    ))}
                </select>
                <div className='address'>
                    <label htmlFor="address">Address<span className="red">*</span></label>
                    {renderConsignorAddressFields()}
                </div>
                <div className='col1'>
                    <div className='state'>
                        <label htmlFor="state">State<span className="red">*</span></label>
                        <input
                            className="consignorinput"
                            type="text"
                            id="state"
                            name="state"
                            placeholder="Enter State"
                            value={consignorData.state}
                            onChange={handleConsignorChange}
                        
                            disabled={consignorData.type === 'Personal '}
                        />
                    </div>
                    <div className='pin_code'>
                        <label htmlFor="pin_code">Pincode<span className="red">*</span></label>
                        <input
                            className="consignorinput mediainput"
                            type="number"
                            min={100000}
                            max={999999}
                            id="pin_code"
                            name="pincode"
                            placeholder="Enter Pincode"
                            value={consignorData.pincode}
                            onChange={handleConsignorChange}
                            
                            disabled={consignorData.type === 'Personal '}
                        />
                    </div>
                </div>
                <div className='consignorcol2'>
                    <div>
                        <label htmlFor="contact">Contact<span className="red">*</span></label>
                        <input
                            className="consignorinput"
                            type="number"
                            id="contact"
                            name="contact"
                            placeholder="Enter Contact"
                            value={consignorData.contact}
                            onChange={handleConsignorChange}
                        
                        />
                    </div>
                    <div>
                        <label htmlFor="gstin">GSTIN<span className="red">*</span></label>
                        <input
                            className="consignorinput mediainput"
                            type="text"
                            id="gstin"
                            name="gstin"
                            placeholder="Enter GSTIN"
                            value={consignorData.gstin}
                            onChange={handleConsignorChange}
                            
                            disabled={consignorData.type === 'Personal '}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row-2-middle">
            <div className='consignor'>
                <div className='liable'>
                    <h4 className='consignorheader'>Consignee</h4>
                    <div>
                        <button type='button' onClick={() => setAddLedgerVisible(true)}>Add Consignee</button>
                        <AddLedgerOverlay isVisible={addLedgerVisible} onClose={closeLedgerOverlay} />
                    </div>
                    <h4 className='consignorheader'>
                        Liable to pay
                        <input
                            type="checkbox"
                            id="consignee"
                            name="consignee"
                            value="consignee"
                            checked={isConsigneeLiable}
                            onChange={handleLiableConsigneeChange}
                        />
                    </h4>
                </div>
                <select className='partyname' name="party" onChange={handleConsigneeChange} value={consigneeData.party}>
                    <option value="">Select Consignee</option>
                    {combinedPartyDetails.map((party) => (
                        <option key={party.id} value={party.name}>{`${party.name} - ${party.type}`}</option>
                    ))}
                </select>
                <div className='address'>
                    <label htmlFor="conaddress">Address<span className="red">*</span></label>
                    {renderConsigneeAddressFields()}
                </div>
                <div className='col1'>
                    <div className='state'>
                        <label htmlFor="constate">State<span className="red">*</span></label>
                        <input
                            className="consignorinput"
                            type="text"
                            id="constate"
                            name="state"
                            placeholder="Enter State"
                            value={consigneeData.state}
                            onChange={handleConsigneeChange}
                         
                            disabled={consigneeData.type === 'Personal '}
                        />
                    </div>
                    <div className='pin_code'>
                        <label htmlFor="conpin_code">Pincode<span className="red">*</span></label>
                        <input
                            className="consignorinput mediainput"
                            type="number"
                            min={100000}
                            max={999999}
                            id="conpin_code"
                            name="pincode"
                            placeholder="Enter Pincode"
                            value={consigneeData.pincode}
                            onChange={handleConsigneeChange}
                         
                            disabled={consigneeData.type === 'Personal '}
                        />
                    </div>
                </div>
                <div className='col2'>
                    <div>
                        <label htmlFor="concontact">Contact<span className="red">*</span></label>
                        <input
                            className="consignorinput"
                            type="number"
                            id="concontact"
                            name="contact"
                            placeholder="Enter Contact"
                            value={consigneeData.contact}
                            onChange={handleConsigneeChange}
                            
                        />
                    </div>
                    <div>
                        <label htmlFor="congstin">GSTIN<span className="red">*</span></label>
                        <input
                            className="consignorinput mediainput"
                            type="text"
                            id="congstin"
                            name="gstin"
                            placeholder="Enter GSTIN"
                            value={consigneeData.gstin}
                            onChange={handleConsigneeChange}
                           
                            disabled={consigneeData.type === 'Personal '}
                        />
                    </div>
                </div>
            </div>
        </div>
                <div className="row-2-right">
                <label className='paymentstatusheader'>Payment Status</label>
                <div className='paymentstatus'>
                <h4 className='paymentstatusamount'>Gross Total - {grossTotal}</h4>
                <h4 className='paymentstatusamount'>Advance - {advance}</h4>
                <h4 className='paymentstatusamount'>Net Payable - {netPayable}</h4>
                </div>
                <div className='status'>
                    <label className='payer'>Frieght Payable by   {isConsignorLiable ? consignorData.party : isConsigneeLiable ? consigneeData.party : 'None'}</label>
                    <h4 className='paymentstatusamount'>
                        <input type="checkbox" id="paid" name="paid" value="paid" checked={paymentStatus === 'paid'} onChange={handlePaymentStatusChange} disabled={netPayable > 0} /> Paid
                    </h4>
                    <h4 className='paymentstatusamount'>
                        <input type="checkbox" id="topay" name="topay" value="topay" checked={paymentStatus === 'topay'} onChange={handlePaymentStatusChange} disabled={netPayable === 0} /> To Pay
                    </h4>
                    <h4 className='paymentstatusamount'>
                        <input type="checkbox" id="tbb" name="tbb" value="tbb" checked={paymentStatus === 'tbb'} onChange={handlePaymentStatusChange} disabled={netPayable === 0} /> TBB
                    </h4>
                    <label className='gstpayer'>{selectedPayer==="Exempted"?"GST":"GST Payable by"} {selectedPayer?selectedPayer:"None"}</label>
                </div>
                </div></div>
                
            </div>
            <div className="invoice-row-3">
        <table className='billitytable'>
            <thead>
            <tr className='billitytr'>
                <th className='billityth'>#</th>
                <th className='billityth'>S.no</th>
                <th className='billityth'>Packages<span className="red">*</span></th>
                <th className='billityth'>Description<span className="red">*</span></th>
                <th className='billityth'>Rate/QTL</th>
                <th className='billityth'>Actual Wt</th>
                <th className='billityth'>Charge Wt</th>
                <th className='billityth'>FTL</th>
            </tr>
            </thead>
            <tbody>
            {rows.map((row, index) => (
                <tr className='billitytrparticulars' key={index}>
                <td className='action'>
                    {rows.length > 1 && (
                    <button  type='button' onClick={() => deleteRow(index)} className="delete-row-button">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    )}
                    {index === rows.length - 1 && (
                    <button type='button'  onClick={addRow} className="add-row-button">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    )}
                </td>
                <td className='s.no bilitytd'>{index + 1}</td>
                <td className='packages bilitytd'><input className="bilityinput bilityinput-package" type='text' name='packages' placeholder='Packages ' value={row.packages} onChange={(e) => handleInputChange(index, e)} /></td>
                <td className='description bilitytd'><input className="bilityinput bilityinput-description" placeholder='Description' type='text' name='description' value={row.description} onChange={(e) => handleInputChange(index, e)} /></td>
                <td className='rpq bilitytd'>{index === 0 ? (<input className="bilityinput" type='number' name='rpq' value={row.rpq} onChange={(e) => handleInputChange(index, e)} disabled={row.disableRPQ} />) : ('-')}</td>
                <td className='actual bilitytd'>{index === 0 ? (<input className="bilityinput" type='number' name='actual' value={row.actual} onChange={(e) => handleInputChange(index, e)} disabled={row.disableActual} />) : ('-')}</td>
                <td className='charges bilitytd'>{index === 0 ? (<input className="bilityinput bilityinput-charges" type='number' name='charges' value={row.charges} onChange={(e) => handleInputChange(index, e)} disabled={row.disableCharges} />) : ('-')}</td>
                <td className='ftl bilitytd'>{index === 0 ? (<input className="bilityinput bilityinput-ftl" type='number' name='ftl' value={row.ftl} onChange={(e) => handleInputChange(index, e)} disabled={row.disableFTL} />) : ('-')}</td>
                </tr>
            ))}
            </tbody>
        </table>

      <table className='bilitytable2'>
        <thead>
          <tr className="bilitytr">
            <th className='billityth freightwdth'>Freight</th>
            <th className='billityth biltywdth'>Bilty</th>
            <th className='billityth'>Kanta</th>
            <th className='billityth'>Labour</th>
            <th className='billityth'>Amount</th>
            <th className='billityth' colSpan="2">GST<span className="red">*</span></th>
            <th className='billityth'>Gross Total</th>
            <th className='billityth'>Advance</th>
            <th className='billityth'>Net Payable</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bilitytr">
            <td className='bilitytd'>{freight}</td>
            <td className='bilitytd'><input type='number'  className='bilityinput'  value={billityCharge} onChange={(e) => setBillityCharge(parseFloat(e.target.value) || 0)} /></td>
            <td className='bilitytd'><input type='number'  className='bilityinput' value={kanta} onChange={(e) => setKanta(parseFloat(e.target.value) || 0)} /></td>
            <td className='bilitytd'><input type='number'  className='bilityinput' value={labour} onChange={(e) => setLabour(parseFloat(e.target.value) || 0)} /></td>
            <td className='bilitytd bilityinput-amt' value={amount}   onChange={(e) => setAmount(e.target.value)}>{calculateAmount()}</td>
            <td className='bilitytd'>
                <select className="gstrate" name="gstRate" id="gstRate" value={gstRate} disabled>
                <option value="">Select GST Rate</option>
                <option value="5.00">Under FCM @ 5%</option>
                <option value="12.00">Under FCM @ 12%</option>
                <option value="rcm">Under RCM</option>
                <option value="exempted">Exempted</option>
                </select>
            </td>
            <td className='bilitytd bilityinput-gstamt'>{gstAmount}</td>
            <td className='bilitytd bilityinput-gtotal'>{grossTotal}</td>
            <td className='bilitytd'><input type='number' value={advance}  className='bilityinput' onChange={handleAdvanceChange} /></td>
            <td className='bilitytd bilityinput-npayable'>{netPayable}</td>
          </tr>
        </tbody>
      </table>
    </div>
                    <div className="invoice-row-4">
                    <table className='billitytable'>
                        <tr className='billitytr'>
                        <th className='billityth'>Private Marks</th>
                            <th className='billityth'>Goods Classification</th>
                            <th className='billityth'>Method of Packing</th>
                            <th className='billityth'>Invoice No.</th>
                            <th className='billityth'>Invoice Value</th>
                            <th className='billityth'>E-Way Bill No</th>
                            <th className='billityth'>Valid Up To</th>
                            <th className='billityth'>GST Payer<span className="red">*</span></th>
                        </tr>
                        <tr className='billitytr'>
                            <td className='privatemarks bilitytd'><input className="bilityinput" type='text' placeholder='Private Marks' value={Private} onChange={(e) => setPrivate(e.target.value)} ></input></td>
                            <td className='goods bilitytd'><input className="bilityinput" type='text' placeholder='Classification of Goods' value={cog} onChange={(e) => setCog(e.target.value)} ></input></td>
                            <td className='mop bilitytd'><input className="bilityinput" type='text' placeholder='Method of Packing' value={methodofP} onChange={(e) => setMethodOfP(e.target.value)} ></input></td>
                            <td className='inoviceno bilitytd'><input className="bilityinput" type='text' placeholder='Invoice No' value={invoicenumber} onChange={(e) => setInvoiceNumber(e.target.value)} ></input></td>
                            <td className='inovicevalue bilitytd'><input className="bilityinput" type='text' placeholder='Invoice Value' value={invoicevalue} onChange={(e) => setInvoiceValue(e.target.value)} ></input></td>
                            <td className='eway bilitytd'><input className="bilityinput" type='text' placeholder='E-Way Bill No' value={eway} onChange={(e) => setEway(e.target.value)} ></input></td>
                            <td className='Valid bilitytd'><input className="bilityinput" type='date' placeholder='Valid up to' value={validdate} onChange={(e) => setValidDate(e.target.value)} ></input></td>
                            <td className='bilitytd'>
                            <select className="gstpay" name="gstpayer" id="gstpayer" value={selectedPayer} disabled>
                            <option value="">Select Payer</option>
                            <option value="Consignor">Consignor</option>
                            <option value="Consignee">Consignee</option>
                            <option value="Transporter">Transporter</option>
                            <option value="Exempted">Exempted</option>
                            </select>
        </td>
                        </tr>
                        
                    </table>
                    </div>
                    <div className="invoice-row-5">
                        <table className='driverdetails'>
                            <tr className="bilitytr">
                    <th className='drivername billityth'>Driver Name</th>
                    <th className='drivermobile billityth'>Mobile No.</th>
                    </tr>
                    <tr className="bilitytr">
                        <td className='bilitytd'><input type='text' className='bilityinput' name='drivername' id='drivername' value={drivername} onChange={(e) => setDriverName(e.target.value)} /></td>
                       <td className='bilitytd'> <input type='text' className='bilityinput' name='drivermobile' id='drivermobile' value={drivermob} onChange={(e) => setDriverMob(e.target.value)} /></td>
                    </tr>
</table> 

<div>

<table className='Bank-details'>
    <tr className="bilitytr">
        <th className='billityth'>Bank Name</th>
        <th className='billityth'>Bank Account No</th>
        <th className='billityth'>IFSC</th>
    </tr>
    <tr className="bilitytr">
        <td className='bilitytd'>{companyDetails.bank_name}</td>
        <td className='bilitytd'>{companyDetails.bank_account_no}</td>
        <td className='bilitytd'>{companyDetails.ifsc}</td>
        </tr>
</table>
</div>
                    </div>
                    <div className='btngaps'>
                    <button className="addclientbutton" type="submit">Save as draft</button>
                    <button className="addclientbutton" type="button" onClick={Updatebiltyasfinal}>Make it final</button>
                   <button className='addclientbutton' type='button' onClick={()=>{navigate('/preview-invoice', { state: { grNo } })}}>Preview</button>
                   <button  className=' addclientbutton' onClick={() => navigate('/clientDashboard/showBillty')}>Back</button>
                    </div>
                    </form>

                   
                    <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}  
            
        />
                      
        </>
    )
}
export default EditBilty;