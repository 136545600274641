import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../styles/showbilty.css';

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;

  return (
    <input
      value={globalFilter || ''}
      onChange={e => setGlobalFilter(e.target.value || undefined)}
      placeholder={`Search ${count} records...`}
      style={{ marginBottom: '10px', width: '100%' }}
    />
  );
}

const ShowBillty = () => {
  const [billties, setBillties] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBillties = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/billtyList', {
          headers: { 'email': email }
        });

        if (response.status === 200) {
          setBillties(response.data);
        } else {
          toast.error('Failed to fetch billties');
        }
      } catch (error) {
        console.error('Error fetching billties:', error);
        toast.error('Failed to fetch billties');
      }
    };

    fetchBillties();
  }, []);

  const isEditable = (createdOn, savedAsDraft, makeItFinal, cancelled) => {
    if (savedAsDraft === 1 || cancelled === 1) {
      return true; // Always editable if saved as draft or cancelled
    } else if (makeItFinal === 1) {
      const hoursDifference = moment().diff(moment(createdOn), 'hours');
      return hoursDifference <= 48;
    } else {
      return false;
    }
  };

  const formatDateTime = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const day = dateTime.getUTCDate();
    const month = dateTime.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
    const year = dateTime.getUTCFullYear();
    let hours = dateTime.getUTCHours();
    const minutes = dateTime.getUTCMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${day} ${month} ${year} ${hours}:${minutes} ${period}`;
  };

  const handleCancel = async (grNo) => {
    Swal.fire({
      title: "Are you sure you want to cancel?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, keep it'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const clientemail = sessionStorage.getItem('email');
        try {
          const response = await axios.post('https://tms-server1.azurewebsites.net/cancelBillty', {
            grNo: grNo,
            clientemail: clientemail
          });
  
          if (response.status === 200) {
            setBillties(prevBillties =>
              prevBillties.map(billty =>
                billty.grNo === grNo ? { ...billty, savedasdraft: 0, makeitfinal: 0, cancelled: 1 } : billty
              )
            );
            toast.success('Billty cancelled successfully');
          } else {
            toast.error('Failed to cancel billty');
          }
        } catch (error) {
          console.error('Error cancelling billty:', error);
          toast.error('Failed to cancel billty');
        }
      }
    });
  };
  const columns = useMemo(
    () => [
      { Header: 'GR-No.', accessor: 'grNo' },
      { Header: 'Date', accessor: 'billtydate', Cell: ({ value }) => moment(value).format('DD-MMM YYYY') },
      { Header: 'Party Name', accessor: 'freightPayableBy' },
      { Header: 'From', accessor: 'inputfrom' },
      { Header: 'To', accessor: 'inputto' },
      { Header: 'Bilty Total', accessor: 'grossTotal' },
      { Header: 'Status', accessor: row => (row.cancelled === 1 ? 'Cancelled' : (row.savedasdraft === 1 ? 'Draft' : 'Final')) },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => {
          const isCompleted = row.original.makeitfinal === 1 && row.original.cancelled === 0 && row.original.savedasdraft === 0;
          const hoursDifference = moment().diff(moment(row.original.created_on), 'hours');
          const within48Hours = hoursDifference <= 48;
          const showEditAndCancel = isCompleted && within48Hours;
          const showEditOnly = row.original.cancelled === 1;

          return (
            <div>
              {showEditAndCancel ? (
                <>
                  <button onClick={() => navigate('/preview-invoice', { state: { grNo: row.original.grNo } })}>
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                  <button onClick={() => navigate('/clientDashboard/edit-billty', { state: { grNo: row.original.grNo } })}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleCancel(row.original.grNo)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </>
              ) : (
                <>
                  {showEditOnly ? (
                    <button onClick={() => navigate('/clientDashboard/edit-billty', { state: { grNo: row.original.grNo } })}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  ) : (
                    <>
                      {row.original.savedasdraft === 1 ? (
                        <>
                          <button onClick={() => navigate('/clientDashboard/edit-billty', { state: { grNo: row.original.grNo } })}>
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button onClick={() => handleCancel(row.original.grNo)}>
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </>
                      ) : (
                        <>
                          <button onClick={() => navigate('/preview-invoice', { state: { grNo: row.original.grNo } })}>
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                          <button onClick={() => handleCancel(row.original.grNo)}>
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          );
        }
      }
    ],
    [navigate]
  );

  const tableInstance = useTable(
    {
      columns,
      data: billties,
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter
  } = tableInstance;

  return (
    <div className='show-bilty'>
      <h2>Bilty List</h2>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()} className='show-bilty-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false}
      />
    </div>
  );
};

export default ShowBillty;
