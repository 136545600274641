import React, { useState, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import 'react-toastify/dist/ReactToastify.css';
import '../styles/ledgerForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const Swal = require('sweetalert2');

const EditLedgerPersonal = () => {
  const location = useLocation(); // Access location state
  const navigate = useNavigate(); // Use navigate to redirect
  const partyData = location.state?.party || {};
  const [ledgerFormData, setFormData] = useState({
    name: partyData.name || '',
    mobile: partyData.contact || '',
    address1: partyData.address1 || '',
    address2: partyData.address2 || '',
    address3: partyData.address3 || '',
    id: partyData.id || '',
    type: partyData.type || ''
  });

  const [errors, setErrors] = useState({ mobile: '' });
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile') {
      if (value.trim() === '') {
        setErrors((prevErrors) => ({ ...prevErrors, mobile: '' }));
      } else {
        const mobileRegex = /^\d{10}$/; // Regular expression to match a 10-digit number
        if (!mobileRegex.test(value)) {
          setErrors((prevErrors) => ({ ...prevErrors, mobile: 'Invalid mobile number' }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, mobile: '' }));
        }
      }
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLedgerSubmit = async (event) => {
    event.preventDefault();

    if (formRef.current.checkValidity()) {
      const clientemail = sessionStorage.getItem('email');
      const dataToSend = {
        ...ledgerFormData,
        clientemail,
      };

      try {
        const resp = await axios.post('https://tms-server1.azurewebsites.net/updatepartydetails', dataToSend);
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Party details updated successfully!',
          showConfirmButton: false,
          timer: 3000
        });

        setFormData({
          name: '',
          mobile: '',
          address1: '',
          address2: '',
          address3: '',
        });
      } catch (error) {
        console.error('Error occurred:', error);
        toast.error(`There was an error! ${error.response?.data?.message || error.message}`);
      }

    } else {
      formRef.current.reportValidity();
    }
  };

  return (
    <>
      <div className="back-button-container">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => navigate(-1)}
          className="back-button"
        />
      </div>
      <form className="ledger-form" ref={formRef}>
        <h3 className="ledger-header">Edit Party Details</h3>
        <div className="ledger-form-group">
          <label htmlFor="name" className="ledger-form-label">
            Name<span className="red">*</span>
          </label>
          <input
            className="ledger-form-input"
            type="text"
            name="name"
            id="name"
            value={ledgerFormData.name}
            onChange={handleChange}
            placeholder="Name"
            required
          />
        </div>
        <div className="ledger-form-group">
          <label htmlFor="mobile" className="ledger-form-label">
            Mobile<span className="red">*</span>
          </label>
          <input
            className="ledger-form-input"
            type="number"
            name="mobile"
            id="mobile"
            value={ledgerFormData.mobile}
            onChange={handleChange}
            placeholder="Mobile Number"
          />
          {errors.mobile && <p className="error red">{errors.mobile}</p>}
        </div>
        <div className="ledger-form-group">
          <label htmlFor="address1" className="ledger-form-label">
            Full Address of Location 1<span className="red">*</span>
          </label>
          <input
            className="ledger-form-input"
            type="text"
            name="address1"
            id="address1"
            value={ledgerFormData.address1}
            onChange={handleChange}
            placeholder="Address"
          />
        </div>
        <div className="ledger-form-group">
          <label htmlFor="address2" className="ledger-form-label">
            Full Address of Location 2<span className="red">*</span>
          </label>
          <input
            className="ledger-form-input"
            type="text"
            name="address2"
            id="address2"
            value={ledgerFormData.address2}
            onChange={handleChange}
            placeholder="Address"
          />
        </div>
        <div className="ledger-form-group">
          <label htmlFor="address3" className="ledger-form-label">
            Full Address of Location 3<span className="red">*</span>
          </label>
          <input
            className="ledger-form-input"
            type="text"
            name="address3"
            id="address3"
            value={ledgerFormData.address3}
            onChange={handleChange}
            placeholder="Address"
          />
        </div>
        <button type="button" onClick={handleLedgerSubmit} className="ledger-form-button">
          Update Ledger
        </button>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false}
      />
    </>
  );
};

export default EditLedgerPersonal;
