import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import '../styles/clientDashboard.css';
import Invoice from './Invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import LedgerForm from './ledgerForm';
import PartyTable from './showLedger';
import ClientProfile from './clientProfile';
import ProfileOverlay from './profileOverlay';
import ShowBillty from './showBillty';
import ShowCashReceipt from './showCashReceipt';
import ShowAdditionalCashReceipt from './showAdditionalCash';
import BiltyPieChart from './biltypiechart';
import FreightChart from './freightChart';
import EditBilty from './editBilty';
import CreateCashReceipt from './createCashReceipts';
import CreateAdditionalReceipt from './createAdditionalCashReceipt';
import ShowPackingList from './showPackingList';
import PersonalLedgerForm from './personalUser';
import EditLedgerBusiness from './editLedger';
import EditLedgerPersonal from './editLedgerPersonal';
import PackingListForm from './createPackingList';
import SetPin from './setPin';

const ClientDashboard = () => {
  const navigate = useNavigate();
  const adminName = "Admin";
  const [isProfileVisible, setProfileVisible] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [openCategories, setOpenCategories] = useState({
    consignmentNote: false,
    ledger: false,
    cashReceipts: false,
    additionalcashReceipts: false,
    packinglistdetails: false
  });
  const [biltyData, setBiltyData] = useState([]);
  const [FreightData, setFreightData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
    try {
        const email = sessionStorage.getItem('email'); // or localStorage.getItem('userEmail')
        
        const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
        headers: {
            'email': email
        }
        });
        console.log(email);
        setCompanyDetails(response.data);
    } catch (error) {
        console.error('Error fetching company details:', error);
    }
    };
    
    fetchData();
}, []);
  useEffect(() => {
    fetchBiltyData();
  }, []);
  useEffect(() => {
    fetchFrieghtData();
  }, []);

  const fetchBiltyData = async () => {
    try {
      const response = await fetch('https://tms-server1.azurewebsites.net/biltyCountByMonth', {
        headers: {
          'Content-Type': 'application/json',
          'email': sessionStorage.getItem('email') // Assuming you store email in sessionStorage
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      const formattedData = data.map(item => ({
        ...item,
        month: moment(item.month, 'M').format('MMMM') // Convert numeric month to month name
      }));
      setBiltyData(formattedData);
    } catch (error) {
      console.error('Error fetching bilty data:', error);
      // Handle error as per your application's requirements
    }
  };
  const fetchFrieghtData = async () => {
    try {
      const response = await fetch('https://tms-server1.azurewebsites.net/freightsum', {
        headers: {
          'Content-Type': 'application/json',
          'email': sessionStorage.getItem('email') // Assuming you store email in sessionStorage
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const Frieghtdata = await response.json();
  
      setFreightData(Frieghtdata);
    } catch (error) {
      console.error('Error fetching Freight data:', error);
      // Handle error as per your application's requirements
    }
  };

  const handlePin = () => {
    navigate('/clientDashboard/setPin');
  };

  const handleLogout = () => {
    sessionStorage.removeItem('email');
    navigate('/cn-login');
  };

  const openProfileOverlay = () => {
    setProfileVisible(true);
    setDropdownVisible(false);
  };

  const closeProfileOverlay = () => {
    setProfileVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const toggleCategory = (category) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <div className="client-dashboard">
      <div className="top-navbar">
        <div className="search-bar">
          
        </div>
        {companyDetails.company_name}
        <div className="profile-menu">
          <button className="profile-btn" onClick={toggleDropdown}>
            <FontAwesomeIcon icon={faUser} className="profile-icon" />
          </button>
          {isDropdownVisible && (
            <div className="dropdown-menu">
              <div className="dropdown-item" onClick={openProfileOverlay}>
                Profile
              </div>
              <div className="dropdown-item" onClick={handlePin}>
                Set Pin
              </div>
              <div className="dropdown-item" onClick={handleLogout}>
                Logout
              </div>
            </div>
          )}
        </div>
        <ProfileOverlay isVisible={isProfileVisible} onClose={closeProfileOverlay} />
      </div>
      <div className="main-content">
        <div className="vertical-navbar">
          <ul className="vertical-navbar__menu">
            <li className="vertical-navbar__item">
              <Link className="vertical-navbar__link" to="">Dashboard</Link>
            </li>
            <li className="vertical-navbar__item">
              <div className="vertical-navbar__main-category" onClick={() => toggleCategory('consignmentNote')}>
                Bilty <FontAwesomeIcon icon={openCategories.consignmentNote ? faCaretDown : faCaretRight} />
              </div>
              {openCategories.consignmentNote && (
                <ul className="vertical-navbar__sub-category">
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="invoice">Add</Link>
                  </li>
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="showBillty">View</Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="vertical-navbar__item">
              <div className="vertical-navbar__main-category" onClick={() => toggleCategory('additionalcashReceipts')}>
                Bill <FontAwesomeIcon icon={openCategories.additionalcashReceipts ? faCaretDown : faCaretRight} />
              </div>
              {openCategories.additionalcashReceipts && (
                <ul className="vertical-navbar__sub-category">
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="createadditionalcashreceipt">Add</Link>
                  </li>
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="showAdditionalCashReceipt">View</Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="vertical-navbar__item">
              <div className="vertical-navbar__main-category" onClick={() => toggleCategory('cashReceipts')}>
                Receipts <FontAwesomeIcon icon={openCategories.cashReceipts ? faCaretDown : faCaretRight} />
              </div>
              {openCategories.cashReceipts && (
                <ul className="vertical-navbar__sub-category">
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="createCashReceipt">Add</Link>
                  </li>
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="showCashReceipt">View</Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="vertical-navbar__item">
              <div className="vertical-navbar__main-category" onClick={() => toggleCategory('packinglistdetails')}>
                Packing lists <FontAwesomeIcon icon={openCategories.packinglistdetails ? faCaretDown : faCaretRight} />
              </div>
              {openCategories.packinglistdetails && (
                <ul className="vertical-navbar__sub-category">
                  {/* <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="createPackingList">Add</Link>
                  </li> */}
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="showPackingList">View</Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="vertical-navbar__item">
              <div className="vertical-navbar__main-category" onClick={() => toggleCategory('ledger')}>
                Ledger <FontAwesomeIcon icon={openCategories.ledger ? faCaretDown : faCaretRight} />
              </div>
              {openCategories.ledger && (
                <ul className="vertical-navbar__sub-category">
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="ledgermaster">Add Business User</Link>
                  </li>
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="personalledgermaster">Add Personal User</Link>
                  </li>
                  <li className="vertical-navbar__sub-item">
                    <Link className="vertical-navbar__sub-link" to="showledger">View</Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
        <div className="content-area">
          <Routes>
            <Route path="/" element={<HomeScreen adminName={adminName} biltyData={biltyData} FreightData={FreightData} />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/ledgermaster" element={<LedgerForm />} />
            <Route path="/personalledgermaster" element={<PersonalLedgerForm />} />
            <Route path="/showledger" element={<PartyTable />} />
            <Route path="/profile" element={<ClientProfile />} />
            <Route path="/showBillty" element={<ShowBillty />} />
            <Route path="/showCashReceipt" element={<ShowCashReceipt />} />
            <Route path="/createCashReceipt" element={<CreateCashReceipt />} />
            <Route path="/showAdditionalCashReceipt" element={<ShowAdditionalCashReceipt />} />
            <Route path="/createadditionalcashreceipt" element={<CreateAdditionalReceipt />} />
            <Route path="/showPackingList" element={<ShowPackingList />} />
            <Route path="/createPackingList" element={<PackingListForm />} />
            <Route path='/edit-billty/' element={<EditBilty/>} />
            <Route path='/edit-ledger-business/' element={<EditLedgerBusiness/>} />
            <Route path='/edit-ledger-personal/' element={<EditLedgerPersonal/>} />
            <Route path='/setPin' element={<SetPin  />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const HomeScreen = ({ adminName, biltyData}) => {
  return (
    <>
    <h1 className='biltyhead'>Bilty Analysis</h1>
    <div className="home-screen">
     
      <BiltyPieChart biltyData={biltyData} />
      <FreightChart />
    </div>
    </>
  );
};

export default ClientDashboard;
