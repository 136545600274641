import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/packingListPreview.css';
import { toast } from 'react-toastify';
import { faPrint,faTrash ,faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const AdminPackingListPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { grNo, email } = location.state || {};
  const [companyDetails, setCompanyDetails] = useState({});
  const [data, setData] = useState(null);
  const [packinglist, setPackingList] = useState([]);
  const [error, setError] = useState(null);
  
  // State to manage rows on both sides (starting with 10 rows each)
  const [leftSideRows, setLeftSideRows] = useState(10);
  const [rightSideRows, setRightSideRows] = useState(10);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        // const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
          headers: { 'email': email }
        });
        setCompanyDetails(response.data);
      } catch (error) {
        console.error('Error fetching company details:', error);
        toast.error('Error fetching company details.');
      }
    };

    fetchClient();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/getbiltypreview', {
          params: { grNo, clientemail: email }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching packing list preview:', error);
        toast.error('Error fetching packing list preview.');
      }
    };

    fetchData();
  }, [grNo]);

  useEffect(() => {
    const fetchPackingListTable = async () => {
      try {
        // const email = sessionStorage.getItem('email');
        const response = await axios.get(`https://tms-server1.azurewebsites.net/getpackinglisttable`, {
          params: { grNo, clientemail: email }
        });
        setPackingList(response.data.packingList);
      } catch (error) {
        console.error('Error fetching packing list data:', error);
        setError('Error fetching packing list data.');
      }
    };
    fetchPackingListTable();
  }, [grNo]);

  if (!data) {
    return <div>Loading...</div>;
  }

  // Ensure data[0] exists and contains the necessary fields
  const biltydateformattedDate = data[0]?.billtydate ? moment(data[0]?.billtydate).format('DD-MMM-YYYY') : '';

  const handleAddRows = () => {
    if (leftSideRows < 30 && rightSideRows < 30) {
      setLeftSideRows(leftSideRows + 10);
      setRightSideRows(rightSideRows + 10);
    }
  };

  const handleRemoveRows = () => {
    if (leftSideRows > 10 && rightSideRows > 10) {
      setLeftSideRows(leftSideRows - 10);
      setRightSideRows(rightSideRows - 10);
    }
  };

  return (
    <div className="packing-preview-container">
      <div className="head-packinglist">
        <div>
          <h2 className='headercompany'>
          <button type="button" className='no-print savebuttonpreview' onClick={() => { navigate(-1) }}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" /> Back
        </button>
            {companyDetails?.company_name}
            <button className='no-print previewbtn' onClick={() => window.print()}>
              <FontAwesomeIcon icon={faPrint} size='lg'/> Print
            </button>
          </h2>
          <p className='upperdata'>Head Office: {companyDetails?.ho_address}, {companyDetails?.ho_city}, {companyDetails?.ho_state}, {companyDetails?.ho_pin_code}</p>
          {companyDetails.bo_address && companyDetails.bo_city && companyDetails.bo_state && companyDetails.bo_pin_code && (
            <p className='upperdata'>Branch Office: {companyDetails.bo_address}, {companyDetails.bo_city}, {companyDetails.bo_state}, {companyDetails.bo_pin_code}</p>
          )}
          <p className='upperdata contactinfo'>Contact: {companyDetails?.contact_no}, Email: {companyDetails?.email}</p>
        </div>
        <div className="">
          <p className='upperdata'>PACKING LIST</p>
        </div>
      </div>

      <form>
        <div className="row-packinglist row1-packinglist">
          <p className='receiptno'>List Number: <span className='rsno'>{data[0]?.listNo || "-"}</span> </p>
          <p className='cashreceiptdate'>Date: <span className='packingspan'>{biltydateformattedDate}</span></p>
        </div>
        <div className="row-packinglist row1-packinglist">
          <p className='receiptno'>GR Number: <span className='rsno'>{data[0]?.grNo[0] || "-"}</span> </p>
          <p className='cashreceiptdate'>Vehicle No: <span className='packingspan'>{data[0]?.vehicleno || "-"}</span></p>
        </div>
        <div className="row-cashreceipt row1-packinglist">
          <p className='receiptno'>Mr/Mrs: <span className='packingspan'>{data[0]?.ConsignorName || "-"}</span> </p>
          <p className='cashreceiptdate'>Mr/Mrs: <span className='packingspan'>{data[0]?.ConsigneeName || "-"}</span></p>
        </div>
        <div className="row-cashreceipt row1-packinglist">
          <p className='receiptno'>From: <span className='packingspan'>{data[0]?.inputfrom || "-"}</span> </p>
          <p className='cashreceiptdate'>To: <span className='packingspan'>{data[0]?.inputto || "-"}</span></p>
        </div>

        <div className="packing-preview-table">
          <table>
            <thead>
              <tr>
                <th className='packing-table-header'>Sr. No.</th>
                <th className='packing-table-header'>Item Name</th>
                <th className='packing-table-header'>Value</th>
                <th className='packing-table-header'>Sr. No.</th>
                <th className='packing-table-header'>Item Name</th>
                <th className='packing-table-header'>Value</th>
              </tr>
            </thead>
            <tbody>
              {/* Loop through left-side rows */}
              {Array.from({ length: leftSideRows }).map((_, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td></td>
                  <td></td>
                  {/* Right-side rows */}
                  <td>{idx + leftSideRows + 1}</td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        {/* Control Buttons for adding/removing rows */}
        <div className="button-group">
          {leftSideRows < 30 && rightSideRows < 30 && (
            <button type="button" onClick={handleAddRows}><FontAwesomeIcon icon={faPlus} /></button>
          )}
          {leftSideRows > 10 && rightSideRows > 10 && (
            <button type="button" onClick={handleRemoveRows}><FontAwesomeIcon icon={faTrash} /></button>
          )}
        </div>
        </div>

        <div className="row2-packinglist">
          <p className='receiptno'></p>
          <p className='cashreceiptdate'>Consignor's Signature</p>
        </div>
        <div className="row-packinglist row1-packinglist">
          <p className='receiptno'>Prepared By</p>
          <p className='cashreceiptdate'><span className='packingspan'>{data[0]?.ConsignorName || "-"}</span></p>
        </div> 
        <div className="row2-packinglist">
          <p className='receiptno'>Accepted By</p>
        </div> 

        
      </form>
    </div>
  );
};

export default AdminPackingListPreview; 
