import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/SetPin.css';

const SetPin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    // Get the email from session storage
    const sessionEmail = sessionStorage.getItem('email');
    if (sessionEmail) {
      setEmail(sessionEmail);
    } else {
      // If no email found, redirect to login
      navigate('/cn-login');
    }
  }, [navigate]);

  const toggleShowPin = () => {
    setShowPin(!showPin); // Toggle PIN visibility
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!pin || pin.length < 4) {
      setError('PIN must be at least 4 characters long.');
      return;
    }

    try {
      const response = await axios.post('https://tms-server1.azurewebsites.net/setPin', {
        email,
        pin,
      });

      if (response.data.success) {
        setSuccess('PIN set successfully!');
        setError('');
        setTimeout(() => {
          navigate('/clientDashboard'); // Redirect to dashboard after successful PIN setup
        }, 2000);
      } else {
        setError('Failed to set PIN. Please try again.');
      }
    } catch (error) {
      console.error('Error setting PIN:', error);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="set-pin-container">
      <form onSubmit={handleSubmit}>
      <h2>Set Your PIN</h2>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} disabled />
        </div>
        <div className="form-group">
          <label>Set PIN:</label>
          <div className="pin-container">
            <input
              type={showPin ? 'text' : 'password'}  // Conditionally set input type
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Enter your PIN"
            />
            <FontAwesomeIcon
              className="toggle-pin"
              onClick={toggleShowPin}
              icon={showPin ? faEye : faEyeSlash} // Change icon based on state
            />
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <button type="submit" className='setpin-button'>Submit</button>
      </form>
    </div>
  );
};

export default SetPin;
