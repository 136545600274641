import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/createadditionalreceipt.css';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment-timezone';
import numberToWords from '@jstb/num-to-words-indian';
import ReactSelect from 'react-select';
import { useNavigate } from 'react-router-dom';

const CreateAdditionalReceipt = () => {
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({});
  const [additionalReceiptNo, setAdditionalReceiptNo] = useState('');
  const [totalamt, setTotalAmt] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [formData, setFormData] = useState({
    date: '',
    receivedFrom: '',
    freight: '',
    transportation: '',
    packing: '',
    loading: '',
    unloading: '',
    unpacking: '',
    insurance: '',
    bikeCharges: '',
    carTransportation: '',
    gstin: '',
    total: '',
    additionalReceiptFor: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [grNumbers, setGrNumbers] = useState([]);
  const [selectedGrNo, setSelectedGrNo] = useState('');
  const [freightPayableByOptions, setFreightPayableByOptions] = useState([]);
  const [selectedFreightPayableBy, setSelectedFreightPayableBy] = useState('');
  const [billtyDetails, setBilltyDetails] = useState({});
  const [dynamicRows, setDynamicRows] = useState([]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
          headers: { email }
        });
        setCompanyDetails(response.data);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };
    fetchCompanyDetails();
  }, []);

  useEffect(() => {
    const fetchAdditionalReceiptNo = async () => {
      try {
        const email = sessionStorage.getItem('email');
        if (!email) throw new Error('Email is not available in session storage');

        const response = await axios.get('https://tms-server1.azurewebsites.net/getadditionalreceiptno', {
          headers: { email }
        });
        setAdditionalReceiptNo(response.data.additionalreceiptno);
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchAdditionalReceiptNo();
  }, []);

  useEffect(() => {
    const fetchFreightPayableByOptions = async () => {
      const email = sessionStorage.getItem('email');
      try {
        const response = await axios.get('https://tms-server1.azurewebsites.net/freightPayers', {
          headers: { email }
        });
        setFreightPayableByOptions(response.data.freightPayers);
      } catch (error) {
        console.error('Error fetching freight payable by options:', error);
      }
    };
    fetchFreightPayableByOptions();
  }, []);

  const handleFreightPayableByChange = async (option) => {
    setSelectedFreightPayableBy(option?.value || '');
    setDynamicRows([]); 
    if (option) {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.post('https://tms-server1.azurewebsites.net/additionalgrnumbers', {
          email,
          freightPayableBy: option.value
        });

        setGrNumbers(response.data.grNumbers || []);
      } catch (error) {
        console.error('Error fetching GR numbers:', error);
      }
    }
  };
  const handleNextBill = () => {
    window.location.reload();
};
  const calculateTotalGross = () => {
    return dynamicRows.reduce((sum, row) => sum + Number(row.grossTotal || 0), 0);
  };
  const totalGross = calculateTotalGross();
  
  const handleGRNoChange = async (e) => {
    const grNo = e.target.value;
    setSelectedGrNo(grNo);
  
    if (grNo) {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/billtydetails', { headers: { email, grNo } });
  
        const newRow = {
          sNo: dynamicRows.length + 1,
          from: response.data.inputfrom,
          to: response.data.inputto,
          biltyDate: response.data.billtydate,
          grossTotal: response.data.grossTotal,
          grNo
        };
  
        setDynamicRows(prevRows => [...prevRows, newRow]);
        
        // Remove selected GR number from the list of available options
        setGrNumbers(prevNumbers => prevNumbers.filter(gr => Number(gr.grNo) !== Number(grNo)));
        setSelectedGrNo('');
        setBilltyDetails(response.data)
      } catch (error) {
        console.error('Error fetching billty details:', error);
      }
    }
  };
  const handleRemoveRow = (grNoToRemove) => {
    // Remove the GR number from dynamic rows
    setDynamicRows((prevRows) => prevRows.filter((row) => row.grNo !== grNoToRemove));
  
    // Re-add removed GR number to the list of available options
    setGrNumbers((prevNumbers) => {
      const removedGRNumber = dynamicRows.find(row => row.grNo === grNoToRemove);
      if (removedGRNumber) {
        return [...prevNumbers, {
          grNo: grNoToRemove,
          billtydate: (removedGRNumber.biltyDate)
        }];
      }
      return prevNumbers;
    });
  };
  
  const handleChange = (e) => {
    const { name, value,type } = e.target;
    if (type === 'number') {
      // Convert value to number and check if it's below 0
      const numericValue = Number(value);
      if (numericValue < 0) {
        toast.error('Value cannot be negative');
        return;
      }
    }
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };

      const freight = Number(updatedFormData.freight || 0);
      const carTransportation = Number(updatedFormData.carTransportation || 0);
      const transportation = Number(updatedFormData.transportation || 0);
      const packing = Number(updatedFormData.packing || 0);
      const loading = Number(updatedFormData.loading || 0);
      const unloading = Number(updatedFormData.unloading || 0);
      const unpacking = Number(updatedFormData.unpacking || 0);
      const insurance = Number(updatedFormData.insurance || 0);
      const bikeCharges = Number(updatedFormData.bikeCharges || 0);
      const gstin = Number(updatedFormData.gstin || 0);
  
      // Calculate the total
      const total = freight + carTransportation + transportation + packing + loading + unloading + unpacking + insurance + bikeCharges + gstin;
  
      // Update the total in form data
      setTotalAmt(total);
      return { ...updatedFormData, total };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const grossTotal = calculateTotalGross();
    if (grossTotal !== Number(formData.total)) {
      toast.error('Total gross does not match the calculated total.');
      return;
    }
    setLoading(true);
    const clientEmail = sessionStorage.getItem('email');
    const grossTotalWords = numberToWords(formData.total || 0) + ' rupees only';
    

   
    const dataToSend = {
      ...billtyDetails,
      additionalReceiptNo,
      grossTotalWords,
      ...formData,
      dynamicRows,
      clientEmail,
      selectedFreightPayableBy,
      totalGross

    };


    try {
      const response = await axios.post('https://tms-server1.azurewebsites.net/createaddreciept', dataToSend);
      toast.success(response.data.message);
      setIsSubmitted(true);
      setIsSaved(true);
    } catch (error) {
      console.error('Error occurred:', error);
      toast.error('Failed to create additional cash receipt');
    } finally {
      setLoading(false);
    }
  };
  const grossTotalWords = numberToWords(formData.total || 0) + ' rupees only';
  return (
    <div className="additional-receipt-container">
      <div className="">
          <p className='packing-list-heading'>Bill</p>
        
      </div>
      <form className="additionalreceiptform" onSubmit={handleSubmit}>
        <div className="row1">
           <div className="selectDiv">
            <label>Freight Payable By:</label>
            <ReactSelect
              value={freightPayableByOptions.find(option => option.value === selectedFreightPayableBy)}
              onChange={handleFreightPayableByChange}
              options={freightPayableByOptions.map(option => ({
                value: option,
                label: option
              }))}
              className="addClientInputselectfreight"
              placeholder="Freight Payable By"
              required
            />
          </div>
          <div className='selectDiv'>
            <label>GR Number:</label>
            <select className="addClientInputselect" value={selectedGrNo} onChange={handleGRNoChange}>
  <option value="">Select GR Number</option>
  {grNumbers.map(grNo => (
    <option key={grNo.grNo} value={grNo.grNo}>Gr No.{grNo.grNo} / Dt:{moment(grNo.billtydate).format('DD-MMM-YYYY')}</option>
  ))}
</select>

          </div>
        </div>

        {dynamicRows.length > 0 && (
          <table className="dynamic-rows-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>GR No</th>
                <th>From</th>
                <th>To</th>
                <th>Bilty Date</th>
                <th>Gross Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dynamicRows.map((row) => (
                <tr key={row.grNo}>
                  <td>{row.sNo}</td>
                  <td>{row.grNo}</td> 
                  <td>{row.from}</td>
                  <td>{row.to}</td>
                  <td>{moment(row.biltyDate).format('DD-MMM-YYYY')}</td>
                  <td>{row.grossTotal}</td>
                  <td>
                    <button type="button" onClick={() => handleRemoveRow(row.grNo)}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

<div className="row1">
              <label>Bill Number:</label>
              <input className="addClientInput" type="text" id="rno" name="rno" value={additionalReceiptNo} disabled />
              <label>Date:</label>
              <input type="date" className="addClientInput" name="date" value={formData.date} onChange={handleChange} required />
              <label>Received From:</label>
              <input type="text" className="addClientInput freightrowinput" name="receivedFrom" value={selectedFreightPayableBy} onChange={handleChange} disabled />
            </div>
            <div className="row2">
              <label>Freight:</label>
              <input type="number" className="addClientInput" name="freight" min='0' value={formData.freight} onChange={handleChange} />
              <label>Transportation:</label>
              <input type="number" className="addClientInput" name="transportation" value={formData.transportation} onChange={handleChange} />
              <label>Packing:</label>
              <input type="number" className="addClientInput" name="packing" value={formData.packing} onChange={handleChange} />
            </div>
            <div className="row3">
             
              <label>Loading:</label>
              <input type="number" className="addClientInput" name="loading" value={formData.loading} onChange={handleChange} />
              <label>Unloading:</label>
              <input type="number" className="addClientInput" name="unloading" value={formData.unloading} onChange={handleChange} />
              <label>Unpacking:</label>
              <input type="number" className="addClientInput" name="unpacking" value={formData.unpacking} onChange={handleChange} />
            </div>

            <div className="row5">
              <label>Insurance:</label>
              <input type="number" className="addClientInput" name="insurance" value={formData.insurance} onChange={handleChange} />
              <label>Bike Charges:</label>
              <input type="number" className="addClientInput" name="bikeCharges" value={formData.bikeCharges} onChange={handleChange} />
              <label>GSTIN:</label>
              <input type="number" className="addClientInput" name="gstin" value={formData.gstin} onChange={handleChange} />
            </div>

            <div className="row5">
              <div className='newrowdata'>
              <label>Car Transportation:</label>
              <input type="number" className="addClientInput" name="carTransportation" value={formData.carTransportation} onChange={handleChange} />
              </div>
         <div className='newrowdata'>
              <label>Total:</label>
              <input type="number" className="addClientInput" name="total" value={totalamt} onChange={handleChange} disabled />
         </div>
            </div>
            <div className="add-row">
              <label className='rsfigure'>Rupees:</label>
              <input type="text" className="addClientInput addrowrupeeinput" value={grossTotalWords} disabled />
            </div>
<div className='btnbill'>
<button type="submit" className='savebuttonbill' >Save</button>
{isSaved && <button className='savebuttonbill' type='button' onClick={()=>{navigate('/additional-cash-receipt-preview', { state: { additionalreceiptno:additionalReceiptNo } })}}>Preview</button>}
{isSaved && <button  className='savebuttonbill' onClick={handleNextBill}>Next Bill</button>}

            </div>

      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateAdditionalReceipt;
