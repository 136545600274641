import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AdminLogin from './components/adminLogin';
import AdminDashboard from './components/adminDashboard';
import ClientDashboard from './components/clientDashboard';
import PreviewInvoice from './components/previewInvoice';
import CashReceiptPreview from './components/cashReceiptPreview';
import AdditionalReceiptPreview from './components/additionalReceiptPreview';
import PackingListPreview from './components/packingListPreview';
import MainScreen from './components/MainScreen';
import AdminPreviewInvoice from './components/adminBiltyPreview';
import AdminAdditionalReceiptPreview from './components/adminAdditionalReceiptPreview';
import AdminCashReceiptPreview from './components/adminCashReceiptPreview';
import AdminPackingListPreview from './components/adminPackingListPreview';
import SetPin from './components/setPin'
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/faq" element={<AdminLogin />} />
        <Route path="/cn-login" element={<Login />} />
        <Route path="/adminDashboard/*" element={<AdminDashboard />} />
        <Route path="/clientDashboard/*" element={<ClientDashboard />} />
        <Route path="/setPin" element={<SetPin />} />
        <Route path="/preview-invoice/*" element={<PreviewInvoice />} />
        <Route path='/cash-receipt-preview/*' element={<CashReceiptPreview/>}/>
        <Route path='/additional-cash-receipt-preview/*' element={<AdditionalReceiptPreview/>}/>
        <Route path='/packing-list-preview/*' element={<PackingListPreview/>}/>
        <Route path='/admin-preview-invoice/*' element={<AdminPreviewInvoice/>}/>
        <Route path='/admin-additional-cash-receipt-preview/*' element={<AdminAdditionalReceiptPreview/>}/>
        <Route path='/admin-cash-receipt-preview/*' element={<AdminCashReceiptPreview/>}/>
        <Route path='/admin-packing-list-preview/*' element={<AdminPackingListPreview/>}/>
      </Routes>
    </Router>
  );
};

export default App;
