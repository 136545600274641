import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AdminShowAdditionalCashReceipt from './adminShowAdditionalCashReceipt';
import AdminShowBillty from './adminShowBilty';
import AdminShowCashReceipt from './adminShowCashReceipt';
import AdminShowPackingList from './adminShowPackingList';
import '../styles/adminShowTabs.css';  // We'll create this CSS file for styling
import { ToastContainer, toast } from 'react-toastify';

const AdminShowTabs = () => {
  const [activeTab, setActiveTab] = useState('bilty');
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const fetchClientDetails = async () => {
    
    try {
      const response = await axios.get(`https://tms-server1.azurewebsites.net/fetchClient/${id}`);
      const clientsData = response.data;
      console.log(clientsData);
        setClientData(clientsData);
    } catch (error) {
      console.error('Error fetching client details:', error);
      toast.error('Error fetching client details');
    }
  };
  useEffect(() => {
    fetchClientDetails();
  }, [id]);
  
  return (
    <div className="admin-show-tabs-container">
      {/* Tab Navigation */}
      <div className="admin-show-tabs-tabs">
        <div 
          className={`admin-show-tabs-tab ${activeTab === 'bilty' ? 'admin-show-tabs-active' : ''}`}
          onClick={() => setActiveTab('bilty')}
          >
          Bilty
        </div>
        <div 
          className={`admin-show-tabs-tab ${activeTab === 'bill' ? 'admin-show-tabs-active' : ''}`}
          onClick={() => setActiveTab('bill')}
          >
          Bill
        </div>
        <div 
          className={`admin-show-tabs-tab ${activeTab === 'receipts' ? 'admin-show-tabs-active' : ''}`}
          onClick={() => setActiveTab('receipts')}
          >
          Receipts
        </div>
        <div 
          className={`admin-show-tabs-tab ${activeTab === 'packingLists' ? 'admin-show-tabs-active' : ''}`}
          onClick={() => setActiveTab('packingLists')}
          >
          Packing Lists
        </div>
      </div>

          {/* <h2>{clientData.company_name}</h2> */}
      {/* Tab Content */}
      <div className="admin-show-tabs-tab-content">
        {activeTab === 'bilty' && <AdminShowBillty id={id} />}
        {activeTab === 'bill' && <AdminShowAdditionalCashReceipt id={id} />}
        {activeTab === 'receipts' && <AdminShowCashReceipt id={id} />}
        {activeTab === 'packingLists' && <AdminShowPackingList id={id} />}
      </div>
    </div>
  );
};

export default AdminShowTabs;
