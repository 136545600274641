import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import '../styles/Showcashreceipttable.css';
import moment from 'moment-timezone';

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;

  return (
    <input
      value={globalFilter || ''}
      onChange={e => setGlobalFilter(e.target.value || undefined)}
      placeholder={`Search ${count} records...`}
      style={{ marginBottom: '10px', width: '100%' }}
    />
  );
}

const AdminShowCashReceipt = ({id}) => {
  const navigate = useNavigate();
  const [cash, setCash] = useState([]);
  const [clientData, setClientData] = useState({});
  const fetchClientDetails = async () => {
    
    try {
      const response = await axios.get(`https://tms-server1.azurewebsites.net/fetchClient/${id}`);
      const clientsData = response.data;
      console.log(clientsData);
        setClientData(clientsData);
        fetchCashReceipt(response.data.email)
    } catch (error) {
      console.error('Error fetching client details:', error);
      toast.error('Error fetching client details');
    }
  };
  useEffect(() => {
    fetchClientDetails();
  }, [id]);

    const fetchCashReceipt = async (email) => {
      try {
        // const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/getcashreceiptdetails', {
          headers: {
            'x-client-email': email
          }
        });

        if (response.status === 200) {
          setCash(response.data);
        } else {
          toast.error('Failed to fetch cash receipts');
        }
      } catch (error) {
        console.error('Error fetching cash receipts:', error);
        toast.error('Failed to fetch cash receipts');
      }
    };

  const columns = useMemo(
    () => [
      { Header: 'Receipt No', accessor: 'cashreceiptno', sortType: 'basic' },
      { Header: 'Receipt Date', accessor: 'cashreceiptdate', sortType: 'basic',Cell: ({ value }) => moment(value).format('DD-MMM YYYY')  },
      { Header: 'Party Name', accessor: 'receivedFrom', sortType: 'basic' },
      { Header: 'Party Address', accessor: 'payerAddress', sortType: 'basic' },
      { Header: 'Amount', accessor: 'grossTotal', sortType: 'basic' },
      { Header: 'Cash / Cheque No', accessor: 'cashOrChequeNo', sortType: 'basic' },
      {
        Header: '#',
        accessor: 'actions',
        Cell: ({ row }) => (
          <button onClick={() => navigate('/admin-cash-receipt-preview', { state: { billNo: row.original.billNo, email:row.original.clientemail } })}>
            <FontAwesomeIcon icon={faEye} />
          </button>
        )
      }
    ],
    [navigate]
  );

  const tableInstance = useTable(
    {
      columns,
      data: cash,
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter
  } = tableInstance;

  return (
    <div className='show-cashrec'>
      <h2 className='cashhead'>Receipt List</h2>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()} className='show-cashrec-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false}
      />
    </div>
  );
};

export default AdminShowCashReceipt;
