import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import numberToWords from '@jstb/num-to-words-indian';
import '../styles/cashReceipt.css';
import 'react-toastify/dist/ReactToastify.css';
import Select  from 'react-select';

import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

const CreateCashReceipt = () => {
  const [billNumbers, setBillNumbers] = useState([]);
  const navigate = useNavigate();
  const [selectedBillNo, setSelectedBillNo] = useState('');
  const [billDetails, setBillDetails] = useState([]);
  const [grNumbers, setGrNumbers] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [cashReceiptNo, setCashReceiptNo] = useState('');
  const [freightPayableBy, setFreightPayableBy] = useState('');
  const [freightPayableByOptions, setFreightPayableByOptions] = useState([]);
  const [selectedFreightPayableBy, setSelectedFreightPayableBy] = useState('');
  const [totalGrossSum, setTotalGrossSum] = useState(0); 
  const [isSaved, setIsSaved] = useState(false);
  const [formData, setFormData] = useState({
    date: '',
    branch: '',
    packages: '',
    cashOrChequeNo: '',
    billNo: '',
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const sumGrossTotal = billDetails.reduce((total, detail) => {
      return total + (Number(detail.grossTotal || 0)); // Summing all grossTotal values
    }, 0);
  
    setTotalGrossSum(sumGrossTotal); 
    // Update the state with the sum
  }, [billDetails]);
  // Fetch GR Numbers

  // Fetch Company Details
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
          headers: { 'email': email }
        });
        setCompanyDetails(response.data);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };
    fetchCompanyDetails();
  }, []);

  // Fetch Cash Receipt Number
  
  useEffect(() => {
    const fetchCashReceiptNo = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/getcashreceiptnumber', {
          headers: { 'email': email }
        });
        setCashReceiptNo(response.data.cashreceiptno);
      } catch (error) {
        toast.error('Error fetching cash receipt number.');
      }
    };
    fetchCashReceiptNo();
  }, []);
  

  useEffect(() => {
    const fetchFreightPayableByOptions = async () => {
      const email = sessionStorage.getItem('email');
      try {
        const response = await axios.get('https://tms-server1.azurewebsites.net/freightPayers', {
          headers: { email }
        });
        setFreightPayableByOptions(response.data.freightPayers || []);
      } catch (error) {
        console.error('Error fetching freight payable by options:', error);
      }
    };
    fetchFreightPayableByOptions();
  }, []);

  const handleFreightPayableByChange = async (selectedOption) => {
    setSelectedFreightPayableBy(selectedOption);
    setBillDetails([])
  
    if (selectedOption) {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.post('https://tms-server1.azurewebsites.net/billnumbers', {
          email,
          freightPayableBy: selectedOption.value,
        });
        
        setBillNumbers(response.data.billNumbers);
        setSelectedBillNo(null); // Clear previous GR number selection if any
      } catch (error) {
        console.error('Error fetching GR numbers:', error);
      }
    }
  };

  const handleBillNoChange = async (e) => {
    const selectedBillNo = e.target.value;
  console.log(selectedBillNo,'seeeeeeeeeelllllllll');
  
    if (selectedBillNo) {
      // Check if this bill is already added to the list
      if (!billDetails.some(detail => detail.billNo === selectedBillNo)) {
        try {
          const email = sessionStorage.getItem('email');
          const response = await axios.post('https://tms-server1.azurewebsites.net/cashbilldetails', {
            email,
            BillNo: selectedBillNo
          });
  
          const fetchedBillDetails = Array.isArray(response.data) ? response.data : [];
  
          if (fetchedBillDetails.length > 0) {
            // Add the bill details to the billDetails array
            setBillDetails(prevDetails => [...prevDetails, ...fetchedBillDetails]);
  
            // Remove the selected bill number from the billNumbers array
            setBillNumbers(prev => prev.filter(bno => Number(bno.additionalreceiptno) !== Number(selectedBillNo)));
  
            // Clear the selected value (ensure `setSelectedBillNo` is connected to the right input)
            setSelectedBillNo('');
          } else {
            console.error('No bill details found for the selected bill number.');
          }
  
        } catch (error) {
          console.error('Error fetching bill details:', error);
        }
      } else {
        toast.error('This Bill No is already added.');
      }
    }
  };
  
  const handleRemoveBill = (billNo) => {
    console.log(billNo,'billNO');
    
    setBillDetails(prevDetails => {
      const updatedDetails = prevDetails.filter(detail => detail.billNo !== billNo);
  console.log(updatedDetails,'updatedDetails');
  
      // Find the removed bill detail
      const removedBill = prevDetails.find(detail => detail.billNo === billNo);
  console.log(removedBill,'removedBill');
  
      if (removedBill) {
        // Re-add the removed bill number back to billNumbers
        setBillNumbers(prev => [
          ...prev, 
          { additionalreceiptno: billNo, additionalreceiptdate: removedBill.biltyDate }
        ]);
      }
  console.log(billNumbers,'billnumbers');
  
      return updatedDetails;
    });
  };
  const handleNextReceipt = () => {
    window.location.reload();
};

  const handleChange = (e) => {
    const { name, value,type } = e.target;
    if (type === 'number') {
      // Convert value to number and check if it's below 0
      const numericValue = Number(value);
      if (numericValue <= 0) {
        toast.error('Value cannot be negative');
        return;
      }
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const clientemail = sessionStorage.getItem('email');
    const dataToSend = {
      ...formData,
      clientemail,
      billNo: billNumbersString,
      grossTotal: totalGrossSum,
      cashReceiptNo:cashReceiptNo,
      freightPayableBy:billDetails.length > 0 && billDetails[0].freightPayableBy ? billDetails[0].freightPayableBy : '',
      payerAddress:billDetails.length > 0 && billDetails[0].payerAddress ? billDetails[0].payerAddress : '',
    };
console.log(freightPayableBy,'freightPayableBy');

    try {
      const response = await axios.post('https://tms-server1.azurewebsites.net/biltycashreceipt', dataToSend);
      toast.success(response.data.message);
      setIsSaved(true);
    } catch (error) {
      toast.error('Failed to create cash receipt.');
    } finally {
      setLoading(false);
    }
  };
  const grossTotalwords = numberToWords(totalGrossSum || 0) + ' rupees only';
  const uniqueBillNumbers = [...new Set(billDetails.map(detail => detail.billNo))];
const billNumbersString = uniqueBillNumbers.join(', ');


  return (
    <div>
      <div className="head-cashreceipt">
        <p className="packing-list-heading">Receipt</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row1">
        <div className='selectDivCashreceipt'>
            <label>Freight Payable By:</label>
            <Select
              options={freightPayableByOptions.map(option => ({ value: option, label: option }))}
              value={selectedFreightPayableBy}
              onChange={handleFreightPayableByChange}
              className="addClientInputselectfreight"
              placeholder='Freight Payable By'
              required
            />
          </div>
<div className='selectDivCashreceipt'>
<label>Select Bill Number:</label>
<select
  className="addClientInput"
  value={selectedBillNo}
  onChange={handleBillNoChange}
>
  <option value="">Select Bill Number</option>
  {billNumbers.map((bno) => (
    <option key={bno.additionalreceiptno} value={bno.additionalreceiptno}>
      Bill No-{bno.additionalreceiptno} / Date: {moment(bno.additionalreceiptdate).format('DD-MMM-YYYY')}
    </option>
  ))}
</select>

          </div>
        </div>

        {billDetails.length > 0 && (
          <div className="dynamic-table">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Bill No</th>
                  <th>GR No</th>
                  <th>Bilty Date</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Gross Total</th>
                  <th>Actions</th> {/* Add Actions column */}
                </tr>
              </thead>
              <tbody>
                {billDetails.map((detail, index) => (
                  <tr key={detail.billNo}>
                    <td>{index + 1}</td>
                    <td>{detail.billNo}</td>
                    <td>{detail.grNo}</td>
                    <td>{moment(detail.biltyDate).format('DD-MMM-YYYY ')}</td>
                    <td>{detail.billFrom}</td>  
                    <td>{detail.billTo}</td>
                    <td>{detail.grossTotal}</td>
                    <td>
                      <button type="button" onClick={() => handleRemoveBill(detail.billNo)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="row1">
           <label>Receipt Number:</label>
              <input className="addClientInput" type="text" id="rno" name="rno" value={cashReceiptNo} disabled />
              <label>Date: </label>
              <input type="date" className="addClientInput" name="date" value={formData.date} onChange={handleChange} required />
              <label>Received From:</label>
  <input 
    type="text" 
    className="addClientInput freightrowinput" 
    name="receivedFrom" 
    value={billDetails.length > 0 && billDetails[0].freightPayableBy ? billDetails[0].freightPayableBy : ''} 
    onChange={handleChange} 
    disabled 
  />
        </div>
        <div className="row4">
          <label>Branch: </label>
          <input type="text" className="addClientInput" name="branch" value={formData.branch} onChange={handleChange}  />
          <label>Packages: </label>
          <input type="text" className="addClientInput" name="packages" value={formData.packages} onChange={handleChange}  />
          <label>Bill No: </label>
          <input type="text" className="addClientInput" name="billNo"  value={billNumbersString} onChange={handleChange} disabled />
        </div>
        <div className="row5">
          <label>Cash/Cheque No: </label>
          <input type="text" className="addClientInput" name="cashOrChequeNo" value={formData.cashOrChequeNo} onChange={handleChange}  />
        </div>
        <div className="row">
            
          <label className='rsfigure'>Rupees: <input type="text" className="addClientInput rowrupeeinput" value={grossTotalwords} disabled /></label>
            </div>
            <div className="row-cashreceipt row7-cashreceipt">
              <p className='cashrupee '>
                <svg className="svg-cashreceipt" fill="#000000" width="20px" height="20px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M308 96c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12H12C5.373 32 0 37.373 0 44v44.748c0 6.627 5.373 12 12 12h85.28c27.308 0 48.261 9.958 60.97 27.252H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h158.757c-6.217 36.086-32.961 58.632-74.757 58.632H12c-6.627 0-12 5.373-12 12v53.012c0 3.349 1.4 6.546 3.861 8.818l165.052 152.356a12.001 12.001 0 0 0 8.139 3.182h82.562c10.924 0 16.166-13.408 8.139-20.818L116.871 319.906c76.499-2.34 131.144-53.395 138.318-127.906H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-58.69c-3.486-11.541-8.28-22.246-14.252-32H308z"></path>
                </svg>
                <input type="number" className="input-cashreceipt rsinput" value={totalGrossSum} disabled />
              </p>
            </div>
<div className='btnbill'>
        <button type="submit" className="savebuttonbilty" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </button>
        {isSaved && <button className='savebuttonbilty' type='button' onClick={()=>{navigate('/cash-receipt-preview', { state: { billNo:billNumbersString } })}}>Preview</button>}
        {isSaved && <button  className='savebuttonbilty' onClick={handleNextReceipt}>Next Bilty</button>}
     
        </div>
      </form>

      <ToastContainer />
    </div>
  );
};

export default CreateCashReceipt;
