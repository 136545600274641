import React, { useRef, useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const BiltyPieChart = ({ biltyData }) => {
  const chartRef = useRef(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // State to hold selected year

  // Filter biltyData based on selectedYear
  const filteredData = biltyData.filter(item => item.year === selectedYear);

  const data = {
    labels: filteredData.map(item => item.month),
    datasets: [
      {
        label: 'Bilty Count',
        data: filteredData.map(item => item.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex;
            const year = filteredData[dataIndex].year;
            const count = filteredData[dataIndex].count;
            return `Year: ${year}, BiltyCount: ${count}`;
          },
        },
      },
      datalabels: {
        display: true,
        color: 'black',
        formatter: (value, context) => {
          return value;
        },
      },
    },
  };

  // Handle year change
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value)); // Update selectedYear state
  };

  return (
    <div style={{ width: '400px', height: '400px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <label htmlFor="year">Select Year:</label>
        <select style={{ padding:'5px', borderRadius:'5px'}} id="year" value={selectedYear} onChange={handleYearChange}>
       
          {[...new Set(biltyData.map(item => item.year))].map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      <Pie ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default BiltyPieChart;
